const dev = {
  API_ENDPOINT: "http://localhost:3003/",
  AUTHORIZATION_TYPE: "Bearer"
  // RECAPTCHA_SITE_KEY: "6Lf93dkZAAAAAOWiX3ZCteqvV_XdX3DMkYNrE3xJ"
};

const stage = {
  API_ENDPOINT: "https://testapi.plingo.com",
  AUTHORIZATION_TYPE: "Bearer"
  // RECAPTCHA_SITE_KEY: "6LeyuewZAAAAAPzVWBaWQtoX1cyuY1FpHwS1Czhd"
};

const prod = {
  API_ENDPOINT: "https://api.plingo.com/",
  AUTHORIZATION_TYPE: "Bearer"
};

function getConfig() {
  if (process.env.NODE_ENV === "production") {
    return prod;
  } else {
    if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'staging') {
      return stage
    }
    return dev;
  }
}

const config = getConfig();

export default config;
