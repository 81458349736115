import { put, takeLatest, call, all, select } from "redux-saga/effects";
import queryString from "query-string";
import ShortUniqueId from "short-unique-id";

import { getApiToken, postApiToken, putApiToken } from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
import { actions as authActions } from "../../Auth/_redux/authRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";

const uid = new ShortUniqueId();
export const actionTypes = {
  SET_LIST_LOADING: "SET_LIST_LOADING",
  GET_USERS_LIST: "GET_USERS_LIST",
  SET_USERS_LIST: "SET_USERS_LIST",
  CHANGE_CURRENT_PAGE: "CHANGE_CURRENT_PAGE",
  CLEAR_LIST: "CLEAR_LIST",
  CHANGE_USER_DATA: "CHANGE_USER_DATA",
  CLEAR_USER_DATA: "CLEAR_USER_DATA",
  SAVE_USER_DATA: "SAVE_USER_DATA",
  SAVE_USER_DATA_LOADING: "SAVE_USER_DATA_LOADING",
  SAVE_USER_DATA_FAILED: "SAVE_USER_DATA_FAILED",
  SAVE_USER_DATA_SUCCESS: "SAVE_USER_DATA_SUCCESS",
  CLOSE_USER_DIALOG: "CLOSE_USER_DIALOG",
  GET_USER: "GET_USER",
  GET_USER_LOADING: "GET_USER_LOADING",
  GET_USER_FAILED: "GET_USER_FAILED",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_LOADING: "UPDATE_USER_LOADING",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILED: "UPDATE_USER_FAILED",
  CHANGE_SORT: "CHANGE_SORT",
  CHANGE_USERS_FILTER_DATA: "CHANGE_USERS_FILTER_DATA",
  CLEAR_USERS_FILTER_DATA: "CLEAR_USERS_FILTER_DATA",
  CLEAR_ALL: "CLEAR_ALL",
  SET_FILTER: "SET_FILTER_USER",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
  UPDATE_CUSTOMER_DETAILS_SUCCESS: "UPDATE_CUSTOMER_DETAILS_SUCCESS",
  UPDATE_CUSTOMER_DETAILS_FAILURE: "UPDATE_CUSTOMER_DETAILS_FAILURE",
  GET_PAYMENTS_LIST: "GET_PAYMENTS_LIST",
  SET_PAYMENTS_LIST: "SET_PAYMENTS_LIST",
  CHANGE_CURRENT_PAGE_PAYMENTS: "CHANGE_CURRENT_PAGE_PAYMENTS",
  SET_CURRENT_PAGE_PAYMENTS: "SET_CURRENT_PAGE_PAYMENTS",
  CHANGE_SORT_PAYMENT:"CHANGE_SORT_PAYMENT",
  CLEAR_LIST_PAYMENT:"CLEAR_LIST_PAYMENT",
  CHANGE_GROUP_DATA:"CHANGE_GROUP_DATA",
  SET_GROUP_DATA: "SET_GROUP_DATA",
  UPDATE_GROUP_DATA: "UPDATE_GROUP_DATA",
  SAVE_GROUP_DATA_LOADING: "SAVE_GROUP_DATA_LOADING",
  SAVE_GROUP_DATA_SUCCESS: "SAVE_GROUP_DATA_SUCCESS",
  GET_GROUPS_LIST: "GET_GROUPS_LIST",
  SET_GROUPS_LIST: "SET_GROUPS_LIST",
  CHANGE_CURRENT_PAGE_GROUPS: "CHANGE_CURRENT_PAGE_GROUPS",
  SET_CURRENT_PAGE_GROUPS: "SET_CURRENT_PAGE_GROUPS",
  CHANGE_SORT_GROUP:"CHANGE_SORT_GROUP",
  CLEAR_LIST_GROUP:"CLEAR_LIST_GROUP",
  CLOSE_GROUP_DIALOG: "CLOSE_GROUP_DIALOG",
  GET_GROUP:"GET_GROUP",
  GET_GROUP_LOADING: "GET_GROUP_LOADING",
  GET_GROUP_SUCCESS: "GET_GROUP_SUCCESS",
  GET_GROUP_FAILED: "GET_GROUP_FAILED",
  SAVE_GROUP_DATA_FAILED:"SAVE_GROUP_DATA_FAILED",
  SET_GROUPS_LIST_MULTI:"SET_GROUPS_LIST_MULTI",
  SET_SELECTED_GROUP:"SET_SELECTED_GROUP",
  SET_LIST_LOADING_GROUP: "SET_LIST_LOADING_GROUP",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_LOADING: "DELETE_USER_LOADING",
};

const initialUsersState = {
  usersList: undefined,
  totalUsersRecords: undefined,
  listLoading: false,
  currentPage: 0,
  maxUsersPerPage: 20,
  usersSortField: "createdAt",
  usersSortOrder: "desc",
  usersFilterData: {
    name: "",
    username: "",
    email: "",
    status: null,
  },
  userData: {
    _id: "",
    name: "",
    emailId: "",
    mobile: "",
    subscription: "",
    nextSubscriptionDate: "",
    sentCount: "",
    emailLimit:"",
    textLimit:"",
    sentCountText:"",
    sentCountEmail:"",
    sentCountEmailCurrentMonth:"",
    sentCountTextCurrentMonth:"",
    views:"",
    isSuspended:false,
    suspensionOrReleaseComment:"",
    isExtensionLimitUpgrade:"",
    isExtensionLimitPermanent:false,
    subscriptions:[],
    subscriptionSelected:"",
    twilioNotifyLongSid:"",
    twilioNotifyLongSidSelected:"",
    subscribeFor:"Monthly",
    dateForAmount: new Date(),
    amountDeduct: 0,
    isPlanDowngrade: false,
    engToEcom: false,
    downgradePlanId: "",
    adImpCm: 0,
    openCm: 0,
    btnClkCm: 0,
    adImpTotal: 0,
    openTotal: 0,
    btnClkTotal: 0,
    paymentFail:"",
    userRestrictionDate:"",
    viewsTotal: 0,
  },
  roles: undefined,
  rolesDropdownLoading: false,
  rolesDropdownFailed: false,
  saveUserDataLoading: false,
  saveUserDataSuccess: false,
  saveUserDataFailed: false,
  updateUserSuccess: false,
  updateUserLoading: false,
  updateUserFailed: false,
  isfilter: false,
  customerSuspensionData: {
    isSuspended: false,
    suspensionOrReleaseComment: ""
  },
  updateCustomerDetailsSuccess: {},
  updateCustomerDetailsFailure: false,
  getUserLoading: false,
  paymentsList: undefined,
  totalPaymentsRecords: undefined,
  currentPagePayment:0,
  paymentsSortField: "createdAt",
  paymentsSortOrder: "desc",
  maxPaymentsPerPage: 10,
  groupData:{
    _id:"",
    name:"",
    status:1,
    userCount: 0,
  },
  getGroupLoading:false,
  getGroupSuccess:false,
  getGroupFailed:false,
  saveGroupDataLoading: false,
  saveGroupDataSuccess: false,
  saveGroupDataFailed: false,
  groupsList: undefined,
  totalGroupsRecords: undefined,
  currentPageGroup:0,
  groupsSortField: "createdAt",
  groupsSortOrder: "desc",
  maxGroupsPerPage: 10,
  groupsListMulti:[],
  selectedGroups:[],
  listLoadingGroup: false,
  deleteUserLoading: false,
};

export const reducer = (state = initialUsersState, action) => {
  switch (action.type) {
    case actionTypes.SET_LIST_LOADING: {
      return {
        ...state,
        listLoading: action.payload.flag,
      };
    }

    case actionTypes.SET_FILTER: {
      const { flag } = action.payload;
      return {
        ...state,
        isfilter: flag,
      };
    }
    case actionTypes.SET_CURRENT_PAGE: {
      const { data } = action.payload;
      return {
        ...state,
        currentPage: data,
      };
    }
    case actionTypes.CLEAR_LIST: {
      return {
        ...state,
        totalUsersRecords: undefined,
        usersList: undefined,
      };
    }

    case actionTypes.SET_USERS_LIST: {
      const { usersData } = action.payload;
      // const totalUsersRecords =
      //   state.isfilter && usersData.recordsTotal
      //     ? usersData.recordsFiltered
      //     : usersData.total;
      const totalUsersRecords = usersData.total;
      const usersList = usersData.data ? usersData.data : undefined;
      return {
        ...state,
        totalUsersRecords,
        usersList,
      };
    }

    case actionTypes.GET_USER_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        getUserLoading: flag,
      };
    }

    case actionTypes.CHANGE_CURRENT_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        currentPage: page,
      };
    }

    case actionTypes.CHANGE_SORT: {
      const { field, order } = action.payload;
      return {
        ...state,
        usersSortField: field,
        usersSortOrder: order,
      };
    }

    case actionTypes.CHANGE_USERS_FILTER_DATA: {
      const { usersFilterData } = action.payload;
      return {
        ...state,
        usersFilterData: usersFilterData,
      };
    }

    case actionTypes.CHANGE_USER_DATA: {
      const { userData } = action.payload;
      // if (!userData.role && userData.roles) {
      //   userData.role =
      //     userData.roles && userData.roles[0] && userData.roles[0].id
      //       ? userData.roles[0].id
      //       : undefined;
      // }
      return {
        ...state,
        userData: {
          _id: userData._id ? userData._id : "",
          name: userData.name ? userData.name : "",
          emailId: userData.emailId ? userData.emailId : "",
          mobile: userData.mobile ? userData.mobile : "",
          subscription: userData.subscription ? userData.subscription : "",
          nextSubscriptionDate: userData.nextSubscriptionDate
            ? userData.nextSubscriptionDate
            : "",
          sentCount: userData.sentCount ? userData.sentCount : "",
          textLimit: userData.textLimit ? userData.textLimit : "",
          emailLimit: userData.emailLimit ? userData.emailLimit : "",
          sentCountEmail: userData.sentCountEmail ? userData.sentCountEmail : "",
          sentCountText: userData.sentCountText ? userData.sentCountText : "",
          sentCountEmailCurrentMonth: userData.sentCountEmailCurrentMonth ? userData.sentCountEmailCurrentMonth : "",
          sentCountTextCurrentMonth: userData.sentCountTextCurrentMonth ? userData.sentCountTextCurrentMonth : "",
          views: userData.views ? userData.views : "",
          isSuspended: userData.isSuspended !== null ? userData.isSuspended : false,
          suspensionOrReleaseComment: userData.suspensionOrReleaseComment ? userData.suspensionOrReleaseComment : "",
          isExtensionLimitPermanent: userData.isExtensionLimitPermanent ? true : false,
          subscriptions: userData.subscriptions ? userData.subscriptions : [],
          subscriptionSelected: userData.subscriptionSelected ? userData.subscriptionSelected : "",
          twilioNotifyLongSid: userData.twilioNotifyLongSid ? userData.twilioNotifyLongSid : "",
          twilioNotifyLongSidSelected: userData.twilioNotifyLongSidSelected ? userData.twilioNotifyLongSidSelected : "",
          subscribeFor:userData.subscribeFor ? userData.subscribeFor : "Monthly",
          dateForAmount: userData.dateForAmount ? userData.dateForAmount : new Date(),
          amountDeduct: userData.amountDeduct ? userData.amountDeduct : "",
          isPlanDowngrade: userData.isPlanDowngrade ? userData.isPlanDowngrade : false,
          engToEcom: userData.engToEcom ? userData.engToEcom : false,
          downgradePlanId: userData.downgradePlanId ? userData.downgradePlanId : "",
          adImpCm: userData.adImpCm ? userData.adImpCm : 0,
          openCm: userData.openCm ? userData.openCm : 0,
          btnClkCm: userData.btnClkCm ? userData.btnClkCm : 0,
          adImpTotal: userData.adImpTotal ? userData.adImpTotal : 0,
          openTotal: userData.openTotal ? userData.openTotal : 0,
          btnClkTotal: userData.btnClkTotal ? userData.btnClkTotal : 0,
          paymentFail: userData.paymentFail ? userData.paymentFail : "",
          userRestrictionDate: userData.userRestrictionDate ? userData.userRestrictionDate : "",
          selectedGroups: userData.selectedGroups ? userData.selectedGroups : [],
          viewsTotal: userData.viewsTotal ? userData.viewsTotal : 0,
        },
      };
    }

    case actionTypes.CLEAR_USER_DATA: {
      return {
        ...state,
        selectedGroups:[],
        userData: {
          _id: "",
          name: "",
          emailId: "",
          mobile: "",
          subscription: "",
          nextSubscriptionDate: "",
          sentCount: "",
          textLimit:"",
          emailLimit:"",
          sentCountText:"",
          sentCountEmail:"",
          sentCountEmailCurrentMonth:"",
          sentCountTextCurrentMonth:"",
          views:"",
          isSuspended:false,
          suspensionOrReleaseComment:"",
          isExtensionLimitUpgrade:"",
          isExtensionLimitPermanent: false,
          subscriptions:[],
          subscriptionSelected:"",
          twilioNotifyLongSid:"",
          twilioNotifyLongSidSelected:"",
          subscribeFor:"Monthly",
          dateForAmount: new Date(),
          amountDeduct: "",
          isPlanDowngrade: false,
          engToEcom: false,
          downgradePlanId: "",
          adImpCm: 0,
          openCm: 0,
          btnClkCm: 0,
          adImpTotal: 0,
          openTotal: 0,
          btnClkTotal: 0,
          paymentFail:"",
          userRestrictionDate:"",
          selectedGroups:"",
          viewsTotal: 0,
        },
      };
    }

    case actionTypes.SAVE_USER_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        saveUserDataLoading: flag,
      };
    }

    case actionTypes.SAVE_USER_DATA_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        saveUserDataFailed: flag,
      };
    }

    case actionTypes.SAVE_USER_DATA_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        saveUserDataSuccess: flag,
      };
    }

    case actionTypes.CLOSE_USER_DIALOG: {
      return {
        ...state,
        selectedGroups:[],
        userData: {
          _id: "",
          name: "",
          emailId: "",
          mobile: "",
          subscription: "",
          nextSubscriptionDate: "",
          sentCount: "",
          textLimit:"",
          emailLimit:"",
          sentCountText:"",
          sentCountEmail:"",
          sentCountEmailCurrentMonth:"",
          sentCountTextCurrentMonth:"",
          views:"",
          isSuspended:false,
          suspensionOrReleaseComment:"",
          isExtensionLimitUpgrade:"",
          isExtensionLimitPermanent: false,
          subscriptions:[],
          subscriptionSelected:"",
          twilioNotifyLongSid:"",
          twilioNotifyLongSidSelected:"",
          subscribeFor:"Monthly",
          dateForAmount: new Date(),
          amountDeduct: "",
          isPlanDowngrade: false,
          engToEcom: false,
          downgradePlanId: "",
          adImpCm: 0,
          openCm: 0,
          btnClkCm: 0,
          adImpTotal: 0,
          openTotal: 0,
          btnClkTotal: 0,
          paymentFail:"",
          userRestrictionDate:"",
          selectedGroups:"",
          viewsTotal: 0,
        },
        saveUserDataLoading: false,
        saveUserDataSuccess: false,
        saveUserDataFailed: false,
        updateUserFailed: false,
        updateUserLoading: false,
        updateUserSuccess: false,
      };
    }

    case actionTypes.UPDATE_USER_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        updateUserSuccess: flag,
      };
    }

    case actionTypes.UPDATE_USER_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        updateUserFailed: flag,
      };
    }

    case actionTypes.UPDATE_USER_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        updateUserLoading: flag,
      };
    }

    case actionTypes.CLEAR_ALL: {
      return {
        usersList: undefined,
        totalUsersRecords: undefined,
        listLoading: false,
        currentPage: 0,
        maxUsersPerPage: 20,
        usersSortField: "id",
        usersSortOrder: "desc",
        usersFilterData: {
          name: "",
          username: "",
          email: "",
          status: null,
        },
        selectedGroups:[],
        userData: {
          _id: "",
          name: "",
          emailId: "",
          mobile: "",
          subscription: "",
          nextSubscriptionDate: "",
          sentCount: "",
          textLimit:"",
          emailLimit:"",
          sentCountText:"",
          sentCountEmail:"",
          sentCountEmailCurrentMonth:"",
          sentCountTextCurrentMonth:"",
          views:"",
          isSuspended:false,
          suspensionOrReleaseComment:"",
          isExtensionLimitUpgrade:"",
          isExtensionLimitPermanent: false,
          subscriptions:[],
          subscriptionSelected:"",
          twilioNotifyLongSid:"",
          twilioNotifyLongSidSelected:"",
          subscribeFor:"Monthly",
          dateForAmount: new Date(),
          amountDeduct: "",
          isPlanDowngrade: false,
          engToEcom: false,
          downgradePlanId: "",
          adImpCm: 0,
          openCm: 0,
          btnClkCm: 0,
          adImpTotal: 0,
          openTotal: 0,
          btnClkTotal: 0,
          paymentFail:"",
          userRestrictionDate:"",
          selectedGroups:"",
          viewsTotal: 0,
        },
        saveUserDataLoading: false,
        saveUserDataSuccess: false,
        saveUserDataFailed: false,
        updateUserSuccess: false,
        updateUserLoading: false,
        updateUserFailed: false,
        isfilter: false,
      };
    }

    case actionTypes.UPDATE_CUSTOMER_DETAILS_SUCCESS: {
      return {
        ...state,
        updateCustomerDetailsSuccess: action.payload,
      };
    }

    case actionTypes.UPDATE_CUSTOMER_DETAILS_FAILURE: {
      return {
        ...state,
        updateCustomerDetailsFailure: action.payload.flag,
      };
    }

    case actionTypes.SET_PAYMENTS_LIST: {
      const { paymentsData } = action.payload;
      const totalPaymentsRecords = paymentsData.total;
      const paymentsList = paymentsData.data ? paymentsData.data : undefined;
      return {
        ...state,
        totalPaymentsRecords,
        paymentsList,
      };
    }

    case actionTypes.CHANGE_CURRENT_PAGE_PAYMENTS: {
      const { page } = action.payload;
      return {
        ...state,
        currentPagePayment: page,
      };
    }

    case actionTypes.SET_CURRENT_PAGE_PAYMENTS: {
      const { data } = action.payload;
      return {
        ...state,
        currentPagePayment: data,
      };
    }

    case actionTypes.CHANGE_SORT_PAYMENT: {
      const { field, order } = action.payload;
      return {
        ...state,
        paymentsSortField: field,
        paymentsSortOrder: order,
      };
    }

    case actionTypes.CLEAR_LIST_PAYMENT: {
      return {
        ...state,
        totalPaymentsRecords: undefined,
        paymentsList: undefined,
      };
    }

    case actionTypes.CHANGE_GROUP_DATA: {
      const { groupData } = action.payload;
      
      return {
        ...state,
        groupData: {
          _id: groupData._id ? groupData._id : "",
          name: groupData.name ? groupData.name : "",
          status: groupData.status ? 1 : 0,
          userCount: groupData.userCount ? groupData.userCount : 0,
        }
      }
    }

    case actionTypes.SAVE_GROUP_DATA_LOADING: {
      return {
        ...state,
        saveGroupDataLoading: action.payload.flag,
      };
    }

    case actionTypes.SAVE_GROUP_DATA_SUCCESS: {
      return {
        ...state,
        saveGroupDataSuccess: action.payload.flag,
      };
    }

    case actionTypes.SAVE_GROUP_DATA_FAILED: {
      return {
        ...state,
        saveGroupDataFailed: action.payload.flag,
      };
    }

    case actionTypes.SET_GROUPS_LIST: {
      const { groupsData } = action.payload;
      const totalGroupsRecords = groupsData.total;
      const groupsList = groupsData.data ? groupsData.data : undefined;
      return {
        ...state,
        totalGroupsRecords,
        groupsList,
      };
    }

    case actionTypes.CHANGE_CURRENT_PAGE_GROUPS: {
      const { page } = action.payload;
      return {
        ...state,
        currentPageGroup: page,
      };
    }

    case actionTypes.SET_CURRENT_PAGE_GROUPS: {
      const { data } = action.payload;
      return {
        ...state,
        currentPageGroup: data,
      };
    }

    case actionTypes.CHANGE_SORT_GROUP: {
      const { field, order } = action.payload;
      return {
        ...state,
        groupsSortField: field,
        groupsSortOrder: order,
      };
    }

    case actionTypes.CLEAR_LIST_GROUP: {
      return {
        ...state,
        totalGroupsRecords: undefined,
        groupsList: undefined,
      };
    }

    case actionTypes.CHANGE_GROUP_DATA: {
      const { groupData } = action.payload;
      
      return {
        ...state,
        groupData: {
          _id: groupData._id ? groupData._id : "",
          name: groupData.name ? groupData.name : "",
          status: groupData.status ? groupData.status : 1,
        }
      }
    }

    case actionTypes.CLOSE_GROUP_DIALOG: {
      return {
        ...state,
        saveGroupDataSuccess:false,
        saveGroupDataLoading: false,
        saveGroupDataFailed:false,
        groupData: {
          _id: "",
          name: "",
          status: 1,
        }
      }
    }

    case actionTypes.GET_GROUP_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        getGroupLoading: flag,
      };
    }

    case actionTypes.GET_GROUP_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        getGroupSuccess: flag,
      };
    }

    case actionTypes.GET_GROUP_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        getGroupFailed: flag,
      };
    }

    case actionTypes.SET_GROUPS_LIST_MULTI: {
      const { groupsData } = action.payload;
      const groupsListMulti = groupsData ? groupsData : undefined;
      return {
        ...state,
        groupsListMulti,
      };
    }

    case actionTypes.SET_SELECTED_GROUP: {
      const { selectedGroups } = action.payload;
      return {
        ...state,
        selectedGroups,
      };
    }

    case actionTypes.SET_LIST_LOADING_GROUP: {
      return {
        ...state,
        listLoadingGroup: action.payload.flag,
      };
    }

    case actionTypes.DELETE_USER_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        deleteUserLoading: flag,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setCurrentPage: (data) => ({
    type: actionTypes.SET_CURRENT_PAGE,
    payload: { data },
  }),
  setFilter: (flag) => ({
    type: actionTypes.SET_FILTER,
    payload: { flag },
  }),
  setListLoading: (flag) => ({
    type: actionTypes.SET_LIST_LOADING,
    payload: { flag },
  }),
  getUsersList: (sortField, sortOrder, page, length, accessToken, search, selectGroup) => ({
    type: actionTypes.GET_USERS_LIST,
    payload: { page, length, accessToken, sortField, sortOrder, search, selectGroup },
  }),
  setUsersList: (usersData) => ({
    type: actionTypes.SET_USERS_LIST,
    payload: { usersData },
  }),
  changeCurrentPage: (page, length, accessToken, search, selectGroup) => ({
    type: actionTypes.CHANGE_CURRENT_PAGE,
    payload: { page, accessToken, length, search, selectGroup },
  }),
  clearList: () => ({ type: actionTypes.CLEAR_LIST }),
  changeSort: (field, order, accessToken, search, selectGroup) => ({
    type: actionTypes.CHANGE_SORT,
    payload: { field, order, accessToken, search, selectGroup },
  }),
  changeUsersFilterData: (usersFilterData) => ({
    type: actionTypes.CHANGE_USERS_FILTER_DATA,
    payload: { usersFilterData },
  }),
  clearUsersFilterData: (accessToken) => ({
    type: actionTypes.CLEAR_USERS_FILTER_DATA,
    payload: { accessToken },
  }),
  changeUserData: (userData) => ({
    type: actionTypes.CHANGE_USER_DATA,
    payload: { userData },
  }),
  clearUserData: () => ({ type: actionTypes.CLEAR_USER_DATA }),
  saveUser: (userData, accessToken) => ({
    type: actionTypes.SAVE_USER_DATA,
    payload: { userData, accessToken },
  }),
  saveUserDataLoading: (flag) => ({
    type: actionTypes.SAVE_USER_DATA_LOADING,
    payload: { flag },
  }),
  saveUserDataFailed: (flag) => ({
    type: actionTypes.SAVE_USER_DATA_FAILED,
    payload: { flag },
  }),
  saveUserDataSuccess: (flag) => ({
    type: actionTypes.SAVE_USER_DATA_SUCCESS,
    payload: { flag },
  }),
  closeUserDialog: () => ({ type: actionTypes.CLOSE_USER_DIALOG }),
  getUser: (id, accessToken) => ({
    type: actionTypes.GET_USER,
    payload: { id, accessToken },
  }),
  getUserLoading: (flag) => ({
    type: actionTypes.GET_USER_LOADING,
    payload: { flag },
  }),
  getUserSuccess: (flag) => ({
    type: actionTypes.GET_USER_SUCCESS,
    payload: { flag },
  }),
  getUserFailed: (flag) => ({
    type: actionTypes.GET_USER_FAILED,
    payload: { flag },
  }),
  changePassword: (password) => ({
    type: actionTypes.CHANGE_PASSWORD,
    payload: { password },
  }),
  updateUser: (id, userData, accessToken) => ({
    type: actionTypes.UPDATE_USER,
    payload: { id, userData, accessToken },
  }),
  updateUserSuccess: (flag) => ({
    type: actionTypes.UPDATE_USER_SUCCESS,
    payload: { flag },
  }),
  updateUserLoading: (flag) => ({
    type: actionTypes.UPDATE_USER_LOADING,
    payload: { flag },
  }),
  updateUserFailed: (flag) => ({
    type: actionTypes.UPDATE_USER_FAILED,
    payload: { flag },
  }),
  clearAll: () => ({ type: actionTypes.CLEAR_ALL }),
  updateCustomerAccount: (userData) => ({
    type: actionTypes.UPDATE_CUSTOMER,
    payload: { userData }
  }),
  updateCustomerDetailsSuccess: (userData) => ({
    type: actionTypes.UPDATE_CUSTOMER_DETAILS_SUCCESS,
    payload: { userData }
  }),
  updateCustomerDetailsFailure: (flag) => ({
    type: actionTypes.UPDATE_CUSTOMER_DETAILS_FAILURE,
    payload: { flag }
  }),
  getPaymentsList: (sortField, sortOrder, page, length, accessToken, search) => ({
    type: actionTypes.GET_PAYMENTS_LIST,
    payload: { page, length, accessToken, sortField, sortOrder, search },
  }),
  setPaymentsList: (paymentsData) => ({
    type: actionTypes.SET_PAYMENTS_LIST,
    payload: { paymentsData },
  }),
  changeCurrentPagePayment: (page, length, accessToken, search) => ({
    type: actionTypes.CHANGE_CURRENT_PAGE_PAYMENTS,
    payload: { page, accessToken, length, search },
  }),
  setCurrentPagePayment: (data) => ({
    type: actionTypes.SET_CURRENT_PAGE_PAYMENTS,
    payload: { data },
  }),
  changeSortPayment: (field, order, accessToken, search) => ({
    type: actionTypes.CHANGE_SORT_PAYMENT,
    payload: { field, order, accessToken, search },
  }),
  clearListPayment: () => ({ type: actionTypes.CLEAR_LIST_PAYMENT }),
  changeGroupData: (groupData) => ({
    type: actionTypes.CHANGE_GROUP_DATA,
    payload: { groupData },
  }),
  updateGroup: (id, groupData, accessToken) => ({
    type: actionTypes.UPDATE_GROUP_DATA,
    payload: { id, groupData, accessToken },
  }),
  setSaveGroupDataLoading: (flag) => ({
    type: actionTypes.SAVE_GROUP_DATA_LOADING,
    payload: { flag },
  }),
  setSaveGroupDataSuccess: (flag) => ({
    type: actionTypes.SAVE_GROUP_DATA_SUCCESS,
    payload: { flag },
  }),
  setSaveGroupDataFailed: (flag) => ({
    type: actionTypes.SAVE_GROUP_DATA_FAILED,
    payload: { flag },
  }),
  getGroupsList: (sortField, sortOrder, page, length, accessToken, fromUser) => ({
    type: actionTypes.GET_GROUPS_LIST,
    payload: { sortField, sortOrder, page, length, accessToken, fromUser },
  }),
  setGroupsList: (groupsData) => ({
    type: actionTypes.SET_GROUPS_LIST,
    payload: { groupsData },
  }),
  changeCurrentPageGroup: (page, length, accessToken) => ({
    type: actionTypes.CHANGE_CURRENT_PAGE_GROUPS,
    payload: { page, accessToken, length },
  }),
  setCurrentPageGroup: (data) => ({
    type: actionTypes.SET_CURRENT_PAGE_GROUPS,
    payload: { data },
  }),
  changeSortGroup: (field, order, accessToken) => ({
    type: actionTypes.CHANGE_SORT_GROUP,
    payload: { field, order, accessToken },
  }),
  clearListGroup: () => ({ type: actionTypes.CLEAR_LIST_GROUP }),
  closeGroupDialog: () => ({ type: actionTypes.CLOSE_GROUP_DIALOG }),
  getGroup: (id, accessToken) => ({
    type: actionTypes.GET_GROUP,
    payload: { id, accessToken },
  }),
  getGroupLoading: (flag) => ({
    type: actionTypes.GET_GROUP_LOADING,
    payload: { flag },
  }),
  getGroupSuccess: (flag) => ({
    type: actionTypes.GET_GROUP_SUCCESS,
    payload: { flag },
  }),
  getGroupFailed: (flag) => ({
    type: actionTypes.GET_GROUP_FAILED,
    payload: { flag },
  }),
  setGroupsListMulti: (groupsData) => ({
    type: actionTypes.SET_GROUPS_LIST_MULTI,
    payload: { groupsData },
  }),
  setSelectedGroup: (selectedGroups) => ({
    type: actionTypes.SET_SELECTED_GROUP,
    payload: { selectedGroups },
  }),
  setListLoadingGroup: (flag) => ({
    type: actionTypes.SET_LIST_LOADING_GROUP,
    payload: { flag },
  }),
  deleteUser: (id,accessToken) => ({
    type: actionTypes.DELETE_USER,
    payload: { id,accessToken },
  }),
  deleteUserLoading: (flag) => ({
    type: actionTypes.DELETE_USER_LOADING,
    payload: { flag },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.CHANGE_CURRENT_PAGE, function* changePageSaga(
    action
  ) {
    const { usersSortField, usersSortOrder } = yield select(
      (state) => state.manageUsers
    );
    const { page, length, accessToken, search, selectGroup } = action.payload;
    yield put(
      actions.getUsersList(
        usersSortField,
        usersSortOrder,
        page,
        length,
        accessToken,
        search,
        selectGroup,
      )
    );
  });

  yield takeLatest(actionTypes.CHANGE_SORT, function* changeSortSaga(action) {
    let { field, order, accessToken, search, selectGroup } = action.payload;
    let { currentPage, maxUsersPerPage } = yield select(
      (state) => state.manageUsers
    );
    yield put(
      actions.getUsersList(
        field,
        order,
        currentPage,
        maxUsersPerPage,
        accessToken,
        search,
        selectGroup,
      )
    );
  });

  yield takeLatest(
    actionTypes.CLEAR_USERS_FILTER_DATA,
    function* clearUsersFilterDataSaga(action) {
      let { accessToken } = action.payload;
      let {
        usersSortField,
        usersSortOrder,
        currentPage,
        maxUsersPerPage,
      } = yield select((state) => state.manageUsers);
      let usersFilterData = {
        name: "",
        username: "",
        email: "",
        status: null,
      };
      yield put(actions.setFilter(false));
      yield put(actions.changeUsersFilterData(usersFilterData));
      yield put(
        actions.getUsersList(
          usersSortField,
          usersSortOrder,
          currentPage,
          maxUsersPerPage,
          accessToken
        )
      );
    }
  );

  yield takeLatest(actionTypes.GET_USERS_LIST, getUsersListSaga);

  yield takeLatest(actionTypes.UPDATE_CUSTOMER, updateCustomerAccountSaga);

  yield takeLatest(actionTypes.SAVE_USER_DATA, saveUserSaga);

  yield takeLatest(actionTypes.GET_USER, getUserSaga);

  yield takeLatest(actionTypes.UPDATE_USER, updateUserSaga);

  yield takeLatest(actionTypes.GET_PAYMENTS_LIST, getPaymentsListSaga);

  yield takeLatest(actionTypes.CHANGE_CURRENT_PAGE_PAYMENTS, function* changePageSaga(
    action
  ) {
    const { paymentsSortField, paymentsSortOrder } = yield select(
      (state) => state.manageUsers
    );
    const { page, length, accessToken, search } = action.payload;
    yield put(
      actions.getPaymentsList(
        paymentsSortField,
        paymentsSortOrder,
        page,
        length,
        accessToken,
        search
      )
    );
  });

  yield takeLatest(actionTypes.CHANGE_SORT_PAYMENT, function* changeSortSaga(action) {
    let { field, order, accessToken, search } = action.payload;
    let { currentPagePayment, maxPaymentsPerPage } = yield select(
      (state) => state.manageUsers
    );
    yield put(
      actions.getPaymentsList(
        field,
        order,
        currentPagePayment,
        maxPaymentsPerPage,
        accessToken,
        search
      )
    );
  });
  yield takeLatest(actionTypes.UPDATE_GROUP_DATA, updateGroupDataSaga);
  yield takeLatest(actionTypes.GET_GROUPS_LIST, getGroupsListSaga);
  yield takeLatest(actionTypes.CHANGE_CURRENT_PAGE_GROUPS, function* changePageSaga(
    action
  ) {
    const { groupsSortField, groupsSortOrder } = yield select(
      (state) => state.manageUsers
    );
    const { page, length, accessToken } = action.payload;
    yield put(
      actions.getGroupsList(
        groupsSortField,
        groupsSortOrder,
        page,
        length,
        accessToken,
        false,
      )
    );
  });

  yield takeLatest(actionTypes.CHANGE_SORT_GROUP, function* changeSortSaga(action) {
    let { field, order, accessToken } = action.payload;
    let { currentPageGroup, maxGroupsPerPage } = yield select(
      (state) => state.manageUsers
    );
    yield put(
      actions.getGroupsList(
        field,
        order,
        currentPageGroup,
        maxGroupsPerPage,
        accessToken,
        false,
      )
    );
  });
  yield takeLatest(actionTypes.GET_GROUP, getGroupSaga);
  yield takeLatest(actionTypes.DELETE_USER, deleteUserSaga);
}

function* getUsersListSaga(action) {
  let { page, length, accessToken, sortField, sortOrder, search, selectGroup } = action.payload;
  yield put(actions.setListLoading(true));
  const { usersFilterData } = yield select((state) => state.manageUsers);
  const filterParams = {
    ...usersFilterData,
    start: page * length,
    length: length,
  };

  try {
    const response = yield call(
      postApiToken,
      apiUrl("GET_CUSTOMERS"),
      accessToken,
      queryString.stringify({
        pageNo: page + 1,
        search: search,
        showRecord: 20,
        sortField: sortField,
        sortOrder: sortOrder,
        selectGroup: selectGroup,
      })
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setUsersList(responseData)),
          put(actions.setListLoading(false)),
          put(actions.setCurrentPage(page))
        ]);
      } else {
        yield all([
          put(actions.clearList()),
          put(actions.setListLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.setListLoading(false));
        yield put(
          generalActions.addToast(
            "Users List",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.setListLoading(false));
      yield put(
        generalActions.addToast(
          "Users List",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setListLoading(false));
    yield put(
      generalActions.addToast(
        "Users List",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* updateCustomerAccountSaga(action) {
  
  let { id, name, mobile, selectedGroups, isSuspended, suspensionOrReleaseComment, textLimit, emailLimit, isExtensionLimitUpgrade, isExtensionLimitPermanent, subscriptionSelected,twilioNotifyLongSid,twilioNotifyLongSidSelected, subscribeFor, dateForAmount, amountDeduct, accessToken } = action.payload?.userData;
  let body = {
    id: id, 
    name: name,
    mobile: mobile,
    isSuspended: isSuspended, 
    suspensionOrReleaseComment: suspensionOrReleaseComment,
    textLimit: textLimit,
    emailLimit: emailLimit,
    isExtensionLimitUpgrade: isExtensionLimitUpgrade,
    isExtensionLimitPermanent: isExtensionLimitPermanent,
    subscriptionSelected: subscriptionSelected,
    twilioNotifyLongSid: twilioNotifyLongSid,
    twilioNotifyLongSidSelected: twilioNotifyLongSidSelected,
    subscribeFor:subscribeFor,
    dateForAmount: dateForAmount,
    amountDeduct: amountDeduct,
    selectedGroups: JSON.stringify(selectedGroups),
  };
  const { userData } = yield select((state) => state.manageUsers);
  let subscriptionSelectedTitle, isSubscriptionChange;
  userData.subscriptions.forEach(function(s){
    if(s._id == subscriptionSelected){
      subscriptionSelectedTitle = s.subscriptionTitle;
    }
  });
  if(subscriptionSelectedTitle){
    if(subscriptionSelectedTitle == userData.subscription){
      isSubscriptionChange = false;
    }else{
      isSubscriptionChange = true;
    }
  }else{
    isSubscriptionChange = false;
  }

  body['isSubscriptionChange'] = isSubscriptionChange;
  body['subscriptionSelectedTitle'] = subscriptionSelectedTitle;
  body['subscription'] = userData.subscription;
  put(actions.saveUserDataSuccess(false))
  try {
    const response = yield call(
      postApiToken,
      apiUrl("UPDATE_CUSTOMER_FROM_ADMIN"),
      "",
      queryString.stringify(body)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if(responseData.message == "Payment Failed"){
        yield put(
          generalActions.addToast(
            "Customer Payment Status",
            "Customer payment failed!",
            "error",
            uid()
          )
        );
        return;
      }
      yield put(
        generalActions.addToast(
          "Customer Update",
          responseData.message,
          "success",
          uid()
        )
      );
      if (responseData) {
        const {
          currentPage,
          maxUsersPerPage,
          usersSortField,
          usersSortOrder,
        } = yield select((state) => state.manageUsers);
        
        yield all([
          put(
            actions.getUsersList(
              usersSortField,
              usersSortOrder,
              currentPage,
              maxUsersPerPage,
              accessToken
            )
          ),
          put(actions.updateCustomerDetailsSuccess(responseData)),
          put(actions.saveUserDataSuccess(true)),
          put(actions.updateCustomerDetailsFailure(false))
        ]);
        
      } else {
        yield all([
          put(actions.saveUserDataSuccess(false)),
          put(actions.updateCustomerDetailsFailure(true))
        ]);
      }
    } 
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserLoading(false)
    );
    yield put(
      generalActions.addToast(
        "Customer suspension status",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* saveUserSaga(action) {
  let { userData, accessToken } = action.payload;
  yield all([
    put(actions.saveUserDataLoading(true)),
    put(actions.saveUserDataSuccess(false)),
    put(actions.saveUserDataFailed(false)),
  ]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("USER_ADMIN_URL"),
      accessToken,
      queryString.stringify(userData)
    );
    if (response.status === 200) {
      const {
        currentPage,
        maxUsersPerPage,
        usersSortField,
        usersSortOrder,
      } = yield select((state) => state.manageUsers);
      yield all([
        put(
          actions.getUsersList(
            usersSortField,
            usersSortOrder,
            currentPage,
            maxUsersPerPage,
            accessToken
          )
        ),
        put(actions.saveUserDataLoading(false)),
        put(actions.saveUserDataSuccess(true)),
        put(actions.saveUserDataFailed(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Save User",
          "User saved successfully!",
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.saveUserDataLoading(false)),
          put(actions.saveUserDataSuccess(false)),
          put(actions.saveUserDataFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Save User",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else if (response.status === 422) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 422) {
        yield all([
          put(actions.saveUserDataLoading(false)),
          put(actions.saveUserDataSuccess(false)),
          put(actions.saveUserDataFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Save User",
            errorData.message,
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.saveUserDataLoading(false)),
        put(actions.saveUserDataSuccess(false)),
        put(actions.saveUserDataFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Save User",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.saveUserDataLoading(false)),
      put(actions.saveUserDataSuccess(false)),
      put(actions.saveUserDataFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Save User",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getUserSaga(action) {
  let { id, accessToken } = action.payload;
  yield all([
    put(actions.getUserLoading(true)),
    put(actions.getUserSuccess(false)),
    put(actions.getUserFailed(false)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("GET_CUSTOMER")}/?userId=${id}`,
      null,
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if(responseData.data['dateForAmount'])
        responseData.data['dateForAmount'] = new Date(responseData.data['dateForAmount']);
        const {
          currentPageGroup,
          maxGroupsPerPage,
          groupsSortField,
          groupsSortOrder,
        } = yield select((state) => state.manageUsers);
        yield all([
          put(actions.changeUserData(responseData.data)),
          put(actions.getUserLoading(false)),
          put(actions.getUserSuccess(true)),
          put(actions.getUserFailed(false)),
          put(
            actions.getGroupsList(
              groupsSortField,
              groupsSortOrder,
              currentPageGroup,
              maxGroupsPerPage,
              accessToken,
              true,
            )
          ),
        ]);
      } else {
        yield all([
          put(actions.getUserLoading(false)),
          put(actions.getUserSuccess(false)),
          put(actions.getUserFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.getUserLoading(false)),
          put(actions.getUserSuccess(false)),
          put(actions.getUserFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Get User",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.getUserLoading(false)),
        put(actions.getUserSuccess(false)),
        put(actions.getUserFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Get User",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.getUserLoading(false)),
      put(actions.getUserSuccess(false)),
      put(actions.getUserFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Get User",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* updateUserSaga(action) {
  let { id, userData, accessToken } = action.payload;
  let body = { ...userData };
  if (!body.password || body.password === "") {
    delete body.password;
  }

  yield all([
    put(actions.updateUserLoading(true)),
    put(actions.updateUserSuccess(false)),
    put(actions.updateUserFailed(false)),
  ]);
  try {
    const response = yield call(
      putApiToken,
      `${apiUrl("USER_ADMIN_URL")}/${id}`,
      queryString.stringify(body),
      accessToken
    );
    if (response.status === 200) {
      const {
        currentPage,
        maxUsersPerPage,
        usersSortField,
        usersSortOrder,
      } = yield select((state) => state.manageUsers);
      yield all([
        put(
          actions.getUsersList(
            usersSortField,
            usersSortOrder,
            currentPage,
            maxUsersPerPage,
            accessToken
          )
        ),
        put(actions.updateUserSuccess(true)),
        put(actions.updateUserLoading(false)),
        put(actions.updateUserFailed(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Update User",
          "User updated successfully!",
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.updateUserLoading(false)),
          put(actions.updateUserSuccess(false)),
          put(actions.updateUserFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Update User",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([
        put(actions.updateUserLoading(false)),
        put(actions.updateUserSuccess(false)),
        put(actions.updateUserFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Update User",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.updateUserLoading(false)),
      put(actions.updateUserSuccess(false)),
      put(actions.updateUserFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Update User",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getPaymentsListSaga(action) {
  let { page, length, accessToken, sortField, sortOrder, search } = action.payload;
  yield put(actions.setListLoading(true));
  try {
    const response = yield call(
      postApiToken,
      apiUrl("GET_PAYMENTS"),
      accessToken,
      queryString.stringify({
        pageNo: page + 1,
        search: search,
        showRecord: 10,
        sortField: sortField,
        sortOrder: sortOrder,
      })
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setPaymentsList(responseData)),
          put(actions.setListLoading(false)),
          put(actions.setCurrentPagePayment(page))
        ]);
      } else {
        yield all([
          put(actions.clearListPayment()),  
          put(actions.setListLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.setListLoading(false));
        yield put(
          generalActions.addToast(
            "Payments List",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.setListLoading(false));
      yield put(
        generalActions.addToast(
          "Payments List",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setListLoading(false));
    yield put(
      generalActions.addToast(
        "Payments List",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* updateGroupDataSaga(action) {
  let { id, groupData, accessToken } = action.payload;
  yield put(actions.setSaveGroupDataLoading(true));
  yield put(actions.setSaveGroupDataFailed(false));
  yield put(actions.setSaveGroupDataSuccess(false));
  try {
    const response = yield call(
      postApiToken,
      apiUrl("ADD_UPDATE_GROUP"),
      accessToken,
      queryString.stringify({
        _id: groupData._id,
        name: groupData.name,
        status: groupData.status,
      })
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if(responseData.statusCode == 401){
        yield put(
          generalActions.addToast(
            "Group Data",
            responseData.message,
            "error",
            uid()
          )
        );
        yield put(actions.setSaveGroupDataLoading(false));
        yield put(actions.setSaveGroupDataSuccess(false));
        yield put(actions.setSaveGroupDataFailed(true));
      }else{
        const {
          currentPageGroup,
          maxGroupsPerPage,
          groupsSortField,
          groupsSortOrder,
        } = yield select((state) => state.manageUsers);
        
        yield all([
          put(
            actions.getGroupsList(
              groupsSortField,
              groupsSortOrder,
              currentPageGroup,
              maxGroupsPerPage,
              accessToken,
              false,
            )
          ),
          put(actions.setSaveGroupDataLoading(false)),
          put(actions.setSaveGroupDataSuccess(true)),
          put(actions.setSaveGroupDataFailed(false)),
        ]);
      }
      
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.setSaveGroupDataLoading(false));
        yield put(actions.setSaveGroupDataSuccess(false));
        yield put(actions.setSaveGroupDataFailed(true));
        yield put(
          generalActions.addToast(
            "Group Data",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.setSaveGroupDataLoading(false));
      yield put(actions.setSaveGroupDataSuccess(false));
      yield put(actions.setSaveGroupDataFailed(true));
      yield put(
        generalActions.addToast(
          "Group Data",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setSaveGroupDataLoading(false));
    yield put(actions.setSaveGroupDataSuccess(false));
    yield put(actions.setSaveGroupDataFailed(true));
    yield put(
      generalActions.addToast(
        "Group Data",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getGroupsListSaga(action) {
  let { page, length, accessToken, sortField, sortOrder, fromUser } = action.payload;
  yield put(actions.setListLoadingGroup(true));
  if(fromUser){
    yield all([
          put(actions.getUserLoading(true)),
        ]);
  }
  try {
    const response = yield call(
      postApiToken,
      apiUrl("GET_GROUP_LIST"),
      accessToken,
      queryString.stringify({
        pageNo: page + 1,
        showRecord: 10,
        sortField: sortField,
        sortOrder: sortOrder,
        fromUser: fromUser,
      })
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if(fromUser){
          let groupMultiData = [];
          let selectedGroup = [];
          const {
            userData,
          } = yield select((state) => state.manageUsers);
          
          responseData.data.forEach(function(val){
            groupMultiData.push({label:val.name,value:val._id});
          });
          let obj;
          if(userData.selectedGroups){
            userData.selectedGroups.forEach(function(val){
              obj = groupMultiData.find(o => o.value === val);
              selectedGroup.push(obj);
            });
          }
          yield put(actions.setSelectedGroup(selectedGroup));
          yield put(actions.setGroupsListMulti(groupMultiData));
        }
        yield all([
          put(actions.setGroupsList(responseData)),
          put(actions.setListLoadingGroup(false)),
          put(actions.setCurrentPageGroup(page)),
          put(actions.getUserLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.clearListGroup()),  
          put(actions.setListLoadingGroup(false)),
          put(actions.getUserLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.setListLoadingGroup(false));
        yield put(actions.getUserLoading(false));
        yield put(
          generalActions.addToast(
            "Groups List",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.setListLoadingGroup(false));
      yield put(actions.getUserLoading(false));
      yield put(
        generalActions.addToast(
          "Groups List",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setListLoadingGroup(false));
    yield put(actions.getUserLoading(false));
    yield put(
      generalActions.addToast(
        "Groups List",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getGroupSaga(action) {
  let { id, accessToken } = action.payload;
  yield all([
    put(actions.getGroupLoading(true)),
    put(actions.getGroupSuccess(false)),
    put(actions.getGroupFailed(false)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("GET_GROUP")}/?groupId=${id}`,
      null,
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.changeGroupData(responseData.data)),
          put(actions.getGroupLoading(false)),
          put(actions.getGroupSuccess(true)),
          put(actions.getGroupFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.getGroupLoading(false)),
          put(actions.getGroupSuccess(false)),
          put(actions.getGroupFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.getGroupLoading(false)),
          put(actions.getGroupSuccess(false)),
          put(actions.getGroupFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Get Group",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.getGroupLoading(false)),
        put(actions.getGroupSuccess(false)),
        put(actions.getGroupFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Get Group",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.getGroupLoading(false)),
      put(actions.getGroupSuccess(false)),
      put(actions.getGroupFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Get Group",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* deleteUserSaga(action) {
  let { id, accessToken } = action.payload;
  yield put(actions.deleteUserLoading(true));
  try {
    const response = yield call(
      postApiToken,
      apiUrl("DELETE_USER"),
      accessToken,
      queryString.stringify({
        id: id,
      })
    );
    const {
      currentPage,
      maxUsersPerPage,
      usersSortField,
      usersSortOrder,
    } = yield select((state) => state.manageUsers);
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Delete User",
          "User deleted successfully!",
          "success",
          uid()
        )
      );
      yield put(
        actions.getUsersList(
          usersSortField,
          usersSortOrder,
          currentPage,
          maxUsersPerPage,
          accessToken
        )
      );
      yield put(actions.deleteUserLoading(false));
      
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.deleteUserLoading(false));
        yield put(
          generalActions.addToast(
            "Delete User",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.deleteUserLoading(false));
      yield put(
        generalActions.addToast(
          "Delete User",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.deleteUserLoading(false));
    yield put(
      generalActions.addToast(
        "Delete User",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}


