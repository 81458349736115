import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table from "../../../components/Table";
import ActionsColumnFormatter from "../components/ActionsColumnFormatter";
import GroupDialog from "../components/GroupDialog";
import { actions as manageUsersActions } from "../redux/manageUsersRedux";

export default function ManageGroup() {
  const dispatch = useDispatch();
  const {
    listLoadingGroup,
    groupsList,
    totalGroupsRecords,
    currentPageGroup,
    maxGroupsPerPage,
    groupsSortField,
    groupsSortOrder,
    // updateCustomerDetailsSuccess
  } = useSelector((state) => state.manageUsers);
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(true);
  const [dialogID, setDialogID] = useState(null);
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    dispatch(manageUsersActions.clearListGroup());
    dispatch(
      manageUsersActions.getGroupsList(
        groupsSortField,
        groupsSortOrder,
        0,
        maxGroupsPerPage,
        cookies.accessToken,
        false,
      )
    );
  }, []);

  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalGroupsRecords ? totalGroupsRecords : 0,
    sizePerPage: maxGroupsPerPage,
    page: currentPageGroup + 1,
  };

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      dispatch(
        manageUsersActions.changeCurrentPageGroup(
          page - 1,
          maxGroupsPerPage,
          cookies.accessToken,
        )
      );
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (sortField !== groupsSortField || sortOrder !== groupsSortOrder) {
        dispatch(
          manageUsersActions.changeSortGroup(
            sortField,
            sortOrder,
            cookies.accessToken
          )
        );
      }
    }
  };

  const onHideUserDialog = () => {
    setShowModal(false);
    setDialogID(null);
    dispatch(manageUsersActions.closeGroupDialog());
  };

  const openEditUserDialog = (edit, id) => {
    setEdit(edit);
    setDialogID(id);
    setShowModal(true);
  };
  
  const tableColumns = [
    {
      dataField: "_id",
      text: "ID",
      formatter: function(cell, row) {
        return row.indexNo;
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sortCaret: sortCaret,
      headerSortingClasses,
      sort: true,
    },
    {
      dataField: "userCount",
      text: "Users",
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
        dataField: "status",
        text: "Status",
        sortCaret: sortCaret,
        headerSortingClasses,
        sort: true,
        formatter: function(cell) {
          return cell ? "Active":"Inactive";
        },
      },
    {
      dataField: "createdAt",
      text: "Created At",
      sortCaret: sortCaret,
      headerSortingClasses,
      sort: true,
      formatter: function(cell, row) {
        return cell.substring(0, 10);
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditUserDialog: (_id) => openEditUserDialog(true, _id),
      }
    },
  ];

  return (
    <Card>
      <CardHeader title="Group list">
        <CardHeaderToolbar>
          <div style={{ marginRight: "10px" }}>
          </div>
          <div className="row search-row">
            <button
              type="button"
              className="btn btn-black-primary"
              onClick={() => openEditUserDialog(false,null)}
            >
              New Group
            </button>
          </div>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Table
          keyField="_id"
          listLoading={listLoadingGroup}
          entities={groupsList ? groupsList : []}
          columns={tableColumns}
          paginationOptions={paginationOptions}
          totalCount={totalGroupsRecords}
          handleTableChange={(type, newState) =>
            handleTableChange(type, newState)
          }
          sortState={{ dataField: groupsSortField, order: groupsSortOrder }}
        />
      </CardBody>
      <GroupDialog
        id={dialogID}
        show={showModal}
        edit={edit}
        onHide={() => onHideUserDialog()}
      />
    </Card>
  );
}
