import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
// import { BuilderPage } from "./pages/BuilderPage";
// import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./modules/Dashboard/DashboardPage";
import UserSettingsPage from "./modules/UserSettings/UserSettingsPage";
import RevenuePayment from "./modules/UserSettings/pages/RevenuePayment";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} /> */}
        <Route path="/dashboard" component={DashboardPage} />
        <Route path="/revenue-payment" component={RevenuePayment} />
        <Route path="/user-settings" component={UserSettingsPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
