import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { actions as manageDashboardActions } from "./redux/manageDashboardRedux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faPaperPlane,
  faEye,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";
import { Accordion, Card, Button, Table } from "react-bootstrap";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import config from "../../../configs/config";
import axios from "axios";
import { getApiToken, postApiToken, putApiToken } from "../../../redux/apis";
import queryString from "query-string";

export function DashboardPage() {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const { dashboardData, getDashboardDataLoading } = useSelector(
    (state) => state.manageDashboard
  );
  const [startDate, setStartDate] = useState(new Date());

  let getmonth, getyear;
  useEffect(() => {
    getmonth = new Date(startDate);
    getyear = getmonth.getFullYear();
    getmonth = getmonth.getMonth() + 1;
    dispatch(
      manageDashboardActions.getDashboardData(
        cookies.accessToken,
        getmonth,
        getyear
      )
    );
  }, []);
  const handleDatePickerChange = (date) => {
    setStartDate(date);
    getmonth = new Date(date);
    getyear = getmonth.getFullYear();
    getmonth = getmonth.getMonth() + 1;
    dispatch(
      manageDashboardActions.getDashboardData(
        cookies.accessToken,
        getmonth,
        getyear
      )
    );
  };
  useEffect(() => {
    var elements = document.getElementsByClassName("clickrow");
    var clickarrow = document.getElementsByClassName("clickarrow");
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove("show");
      clickarrow[i].setAttribute("aria-expanded", "false");
    }
  }, [dashboardData]);
  const mystyle = {
    overflow: "hidden",
    height: "18em",
    overflowY: "scroll",
  };
  return (
    <>
      <div className="row overlay overlay-block cursor-default">
        {getDashboardDataLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}
        <div className="col-12 my-4">
          <h3>Total Plingo Send (Text, Email, View)</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl mb-8">
          {/* <!-- Value  --> */}
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center gx-0">
                <div className="col">
                  {/* <!-- Title --> */}
                  <h6 className="text-uppercase text-muted mb-2">Text</h6>

                  {/* <!-- Heading --> */}
                  <span className="h2 mb-0">
                    {dashboardData.totalSentCountText}
                  </span>
                </div>
                <div className="col-auto">
                  {/* <!-- Icon --> */}
                  {/* <FontAwesomeIcon icon={faCoffee} /> */}
                  {/* <span className="h2 fe fe-dollar-sign text-muted mb-0"></span> */}
                  {/* <i className="fas fa-heart"></i>; */}
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="fa-2x text-primary"
                  />
                </div>
              </div>
              {/* <!-- / .row --> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl mb-8">
          {/* <!-- Hours --> */}
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center gx-0">
                <div className="col">
                  {/* <!-- Title --> */}
                  <h6 className="text-uppercase text-muted mb-2">Email</h6>
                  {/* <!-- Heading --> */}
                  <span className="h2 mb-0">
                    {dashboardData.totalSentCountEmail}
                  </span>
                </div>
                <div className="col-auto">
                  {/* <!-- Icon --> */}
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    className="fa-2x text-primary"
                  />
                </div>
              </div>
              {/* <!-- / .row --> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl mb-8">
          {/* <!-- Exit --> */}
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center gx-0">
                <div className="col">
                  {/* <!-- Title --> */}
                  <h6 className="text-uppercase text-muted mb-2">Viewed</h6>

                  {/* <!-- Heading --> */}
                  <span className="h2 mb-0">{dashboardData.totalViews}</span>
                </div>
                <div className="col-auto">
                  <FontAwesomeIcon
                    icon={faEye}
                    className="fa-2x text-primary"
                  />
                </div>
              </div>
              {/* <!-- / .row --> */}
            </div>
          </div>
        </div>
        {/* <div className="col-12 col-lg-6 col-xl mb-8">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center gx-0">
                <div className="col">
                  <h6 className="text-uppercase text-muted mb-2">% of sends</h6>

                  <span className="h2 mb-0">0%</span>
                </div>
                <div className="col-auto">
                  <FontAwesomeIcon
                    icon={faPercent}
                    className="fa-2x text-primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="row">
        <div className="col-12 col-lg-6 col-xl mb-8">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center gx-0">
                <div className="col">
                  <h6 className="text-uppercase text-muted mb-2">
                    Plingo created
                  </h6>

                  <span className="h2 mb-0">3</span>
                </div>
                <div className="col-auto">
                  
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="fa-2x text-primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl mb-8">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center gx-0">
                <div className="col">
                  <h6 className="text-uppercase text-muted mb-2">
                    PLINGO SENDS
                  </h6>

                  <span className="h2 mb-0">763.5</span>
                </div>
                <div className="col-auto">
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    className="fa-2x text-primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-xl-6">
          <div className="card card-custom gutter-b">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">Top 10 plingo send</h3>
              </div>
            </div>
            <div className="card-body">
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th>Name</th>
                    <th>Sends</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData &&
                    dashboardData.topPlingos &&
                    dashboardData.topPlingos.map((data, index) => (
                      <tr key={"ps"+index}>
                        <td>
                          {data.plingoDetails
                            ? data.plingoDetails.name
                            : "Untitled"}
                        </td>
                        <td>{data.sentCount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="card card-custom gutter-b">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">Top 10 plingo view</h3>
              </div>
            </div>
            <div className="card-body">
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th>Name</th>
                    <th>Views</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData &&
                    dashboardData.topPlingoViews &&
                    dashboardData.topPlingoViews.map((data, index) => (
                      <tr key={"pv"+index}>
                        <td>
                          {data.plingoDetails
                            ? data.plingoDetails.name
                            : "Untitled"}
                        </td>
                        <td>{data.views}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12  mb-3">
          <div className="card card-custom gutter-b">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">Top 10 customers</h3>
              </div>
            </div>
            <div className="card-body">
              <table className="table  table-head-custom table-vertical-center overflow-hidden">
                <thead className="table-dark">
                  <tr>
                    <th className="text-white">Name</th>
                    <th className="text-white">Email</th>
                    <th className="text-white">Mobile</th>
                    <th className="text-white">Email Send</th>
                    <th className="text-white">Text Send</th>
                    <th className="text-white">Ad Impressions</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData &&
                    dashboardData.topCustomers &&
                    dashboardData.topCustomers.map((data, index) => (
                      <tr key={"pc"+index}>
                        <td>{data.userDetails.name}</td>
                        <td>{data.userDetails.emailId}</td>
                        <td>{data.userDetails.mobile}</td>
                        <td>{data.userDetails.sentEmailCount}</td>
                        <td>{data.userDetails.sentTextCount}</td>
                        <td>{data.userDetails.adImp}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
