import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import ShortUniqueId from "short-unique-id";

import { Input, Select } from "../../../../_metronic/_partials/controls";
import { actions as manageUsersActions } from "../redux/manageUsersRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";
import "react-datepicker/dist/react-datepicker.css";
const uid = new ShortUniqueId();

// Validation schema
const UserEditSchema = (password) =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

export function GroupForm({ groupID, actionsLoading, onHide, edit }) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const {
    groupData,
    getGroupLoading,
    saveGroupDataSuccess,
    saveGroupDataFailed,
    getGroupFailed,
  } = useSelector((state) => state.manageUsers);

  const [formLoading, setFormLoading] = useState(false);

  const handleFormChange = (e) => {
    let data = groupData;
    data[e.target.name] = e.target.value;
    dispatch(manageUsersActions.changeUserData(data));
  };

  useEffect(() => {
    if (
      getGroupLoading
    ) {
      setFormLoading(true);
    } else {
      setFormLoading(false);
    }
  }, [
    getGroupLoading]);

  useEffect(() => {
    if (groupID && edit) {
      dispatch(manageUsersActions.getGroup(groupID, cookies.accessToken));
    }
  }, []);
  
  useEffect(() => {
    if (saveGroupDataSuccess && !saveGroupDataFailed) {
      setFormLoading(false);
      onHide();
    }else{
      setFormLoading(false);
    }
  }, [saveGroupDataSuccess,saveGroupDataFailed]);

  const saveGroup = () => {    
    setFormLoading(true);
    if(edit){
      if(groupData.userCount && groupData.status !== 1){
        dispatch(generalActions.addToast(
          "Group Update",
          "One or more user assigned to this group please remove user from this group first.",
          "error",
          uid()
        ));
        setFormLoading(false);
      }else{
        dispatch(
          manageUsersActions.updateGroup(groupID,groupData, cookies.accessToken)
        );
      }
    }else{
        dispatch(
            manageUsersActions.updateGroup(null,groupData, cookies.accessToken)
          );
    }
  };

  if (
    (!getGroupLoading && getGroupFailed)
  ) {
    return (
      <div
        style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}
      >
        <i className="flaticon-warning"></i>
        <p>
          Something went wrong. Please check your internet connection and try
          again.
        </p>
      </div>
    );
  } else {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={groupData}
          validationSchema={UserEditSchema}
          handleChange={(e) => handleFormChange(e)}
          onSubmit={() => {
            saveGroup();
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                {formLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div style={{zIndex: 1}} className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                <div className="card form-group">
                  <div className=" bg-primary card-title py-2 px-3"
                  >
                    <label className=" text-light mb-0 font-weight-bold">Group</label>
                    </div>
                    <div className="col-12">
                    <div className="form-group row">
                    {/* Name */}
                    <div className="col-lg-4">
                      <label>Name</label>
                      <Field
                        value={groupData.name}
                        name="name"
                        component={Input}
                        placeholder="Name"
                        onChange={(e) => handleFormChange(e)}
                        disabled={false}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Status</label>
                      <Select
                        id="status"
                        onChange={(e) => handleFormChange(e)}
                        value={groupData.status}
                        name="status" 
                        disabled={formLoading}
                      >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Select>

                    </div>
                  </div>
                  </div>
                  </div> 
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={!formLoading ? onHide : undefined}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="button"
                  onClick={!formLoading ? onHide : undefined}
                  onClick={() => !formLoading && handleSubmit()}
                  className="btn btn-primary btn-elevate"
                  disabled={formLoading}
                >
                  Submit
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    );
  }
}
