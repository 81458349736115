/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* System */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text" style={{ fontSize: "1.23rem" }}>
            SYSTEM
          </h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/revenue-payment", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/revenue-payment">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />
            </span>
            <span className="menu-text">Revenues and Payments</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/user-settings",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-settings">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Users Settings</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className="menu-item menu-item-parent"
                aria-haspopup="true"
              >
                <span className="menu-link">
                  <span className="menu-text">Users Settings</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/user-settings/manage-users",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/user-settings/manage-users">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Manage Users</span>
                </NavLink>
                
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/user-settings/manage-group",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/user-settings/manage-group">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Manage Groups</span>
                </NavLink>
                
              </li>
            </ul>
          </div>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
