/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import PasswordGenerator from "./PasswordGenerator";
import ShortUniqueId from "short-unique-id";

import { Input, Select, TextArea, Checkbox } from "../../../../_metronic/_partials/controls";
import { actions as manageUsersActions } from "../redux/manageUsersRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelect from "react-multi-select-component";
import config from "../../../../configs/config";
const uid = new ShortUniqueId();

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// Validation schema
const UserEditSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required()
  });

export function UserForm({ userID, actionsLoading, onHide, edit }) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const {
    rolesDropdownFailed,
    rolesDropdownLoading,
    roles,
    userData,
    getUserLoading,
    getUserSuccess,
    getUserFailed,
    saveUserDataLoading,
    saveUserDataFailed,
    saveUserDataSuccess,
    updateUserFailed,
    updateUserLoading,
    updateUserSuccess,
    updateCustomerDetailsSuccess,
    updateCustomerDetailsFailure,
    groupsListMulti,
    selectedGroups,
  } = useSelector((state) => state.manageUsers);

  const [changePassword, setChangePassword] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const [rolesSuccess, setRolesSuccess] = useState(false);
  const [textLimitBefore, setTextLimitBefore] = useState(false);
  const [emailLimitBefore, setEmailLimitBefore] = useState(false);
  const [dropDownMonYearDisable, setDropDownMonYearDisable] = useState(true);
  const [dateAmountRow, setDateAmountRow] = useState(true);
  const [enableExtention, setEnableExtention] = useState(true);
  const [msgForUpgradeDowngrade, setMsgForUpgradeDowngrade] = useState(true);
  const [isSpecialPlan, setIsSpecialPlan] = useState(false);
  const [twilioServicesList, setTwilioServicesList] = useState([]);
  
  const handleFormChange = (e,isDatePicker) => {
    let data = userData;
    if(isDatePicker){
      data['dateForAmount'] = e;
    }else{
      e.target.type === 'checkbox' ? data[e.target.name] = e.target.checked : data[e.target.name] = e.target.value;
      if(e.target.name == "subscriptionSelected"){
        userData.subscriptions.forEach(function(s){
          if(s._id == e.target.value){
            let data = userData;
            if(s.textLimit && s.emailLimit){
              data['textLimit'] = s.textLimit;
              data['emailLimit'] = s.emailLimit;
            }
            if(userData.subscription == "Express" && (s.subscriptionTitle == "Engage" || s.subscriptionTitle == "e-Commerce")){
              setDropDownMonYearDisable(false);
            }else{
              setDropDownMonYearDisable(true);
            }
            if(userData.subscription == "Engage" && s.subscriptionTitle == "e-Commerce"){
              setDateAmountRow(false);
            }else{
              setDateAmountRow(true);
            }
            if(s.subscriptionTitle == "e-Commerce" || s.subscriptionTitle == "Engage" || s.subscriptionTitle == "Special"){
              setEnableExtention(false);
            }else{
              setEnableExtention(true);
            }
            if(s.subscriptionTitle == "Special"){
              setIsSpecialPlan(true)
            }else{
              setIsSpecialPlan(false)
            }
          }
          
        });
      }
    }
    
    
    dispatch(manageUsersActions.changeUserData(data));
  };

  useEffect(() => {
    if (
      getUserLoading
    ) {
      setFormLoading(true);
    } else {
      setFormLoading(false);
    }
    if(userData.textLimit && userData.emailLimit){
      
      setEmailLimitBefore(userData.emailLimit);
      setTextLimitBefore(userData.textLimit);
    }
    if(userData.subscription == "Free" || userData.subscription == "Express"){
      setEnableExtention(true);
    }else{
      setEnableExtention(false);
    }
    if(userData.subscriptions){
      userData.subscriptions.forEach(function(s){
        if(s.subscriptionTitle == userData.subscription){
          let data = userData;
          data['subscriptionSelected'] = s._id;
          dispatch(manageUsersActions.changeUserData(data));
        }
      });
    }
    
    if(userData.isPlanDowngrade){
      let getPlanName;
      userData.subscriptions.forEach(function(s){
        if(s._id == userData.downgradePlanId){
          getPlanName = s.subscriptionTitle;
        }
      });
      setMsgForUpgradeDowngrade("Plan downgrade to "+getPlanName+" will effect in next billing cycle");
    }
    if(userData.engToEcom){
      setMsgForUpgradeDowngrade("Plan upgraded to e-Commerce will effect in next billing cycle");
    }
  }, [
    getUserLoading]);

    const twilioServices = async()=>{
      const requestOptions = {
              method: "POST",
            };
    const response = await fetch(`${config.API_ENDPOINT}api/v1/longCode/getTwilioServices`,requestOptions)
    const responseData = await response.json();
    if(responseData?.statusCode === 200 && responseData?.data)
    {
      setTwilioServicesList(responseData?.data)
    }
    }
  useEffect(() => {
    if (userID && edit) {
      dispatch(manageUsersActions.getUser(userID, cookies.accessToken));
      twilioServices();
    }
    // dispatch(manageUsersActions.getRolesDropdown(cookies.accessToken));
  }, []);

  useEffect(() => {
    if (!saveUserDataLoading && !saveUserDataFailed && saveUserDataSuccess) {
      onHide();
    }
  }, [saveUserDataFailed, saveUserDataLoading, saveUserDataSuccess]);

  useEffect(() => {
    if (!updateCustomerDetailsFailure && saveUserDataSuccess) {
      setFormLoading(false);
      onHide();
    }
  }, [saveUserDataSuccess, updateCustomerDetailsFailure]);

  const saveUser = () => {
    let isExtensionLimitUpgrade = false;
    // if(textLimitBefore>userData.textLimit || emailLimitBefore>userData.emailLimit ){
    //   dispatch(generalActions.addToast(
    //     "Plingo Extension",
    //     "Email or Text limit should not be downgrade.",
    //     "error",
    //     uid()
    //   ));
    //   return;
    // }else{
    //   if((userData.textLimit && userData.textLimit > textLimitBefore) || (userData.emailLimit && userData.emailLimit > emailLimitBefore)){
    //     isExtensionLimitUpgrade = true;
    //   }else{
    //     isExtensionLimitUpgrade = false;
    //   }
    // }
    const dataToSave = {
      id: userData._id,
      name: userData.name,
      mobile: userData.mobile,
      isSuspended: userData.isSuspended,
      suspensionOrReleaseComment: userData.suspensionOrReleaseComment,
      textLimit: userData.textLimit,
      emailLimit: userData.emailLimit,
      isExtensionLimitUpgrade: isExtensionLimitUpgrade,
      isExtensionLimitPermanent: userData.isExtensionLimitPermanent,
      subscriptionSelected: userData.subscriptionSelected,
      twilioNotifyLongSid: userData.twilioNotifyLongSid,
      twilioNotifyLongSidSelected: userData.twilioNotifyLongSidSelected,
      subscribeFor: userData.subscribeFor,
      dateForAmount: userData.dateForAmount,
      amountDeduct: userData.amountDeduct,
      accessToken: cookies.accessToken,
      selectedGroups: selectedGroups,
    };
    setFormLoading(true);
      dispatch(
        manageUsersActions.updateCustomerAccount(dataToSave)
      );
  };

  const setSelectedGroups = (val) => {
    dispatch(
      manageUsersActions.setSelectedGroup(val)
    );
  }
  
  
  const renderPasswordField = () => {
    if (edit && userID) {
      if (changePassword) {
        return (
          <div>
            <Field
              value={userData.password}
              onChange={(e) => handleFormChange(e)}
              type="password"
              name="password"
              component={Input}
              placeholder="Password"
              label="Password"
              disabled={formLoading}
            />
            <PasswordGenerator
              setAutoPassword={(password) =>
                dispatch(manageUsersActions.changePassword(password))
              }
            />
          </div>
        );
      } else {
        return (
          <div
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "#FFA500",
            }}
            onClick={() => !formLoading && setChangePassword(true)}
          >
            Change Password
          </div>
        );
      }
    }
    return (
      <div>
        <Field
          value={userData.password}
          onChange={(e) => handleFormChange(e)}
          type="password"
          name="password"
          component={Input}
          placeholder="Password"
          label="Password"
          disabled={formLoading}
        />
        <PasswordGenerator
          setAutoPassword={(password) =>
            dispatch(manageUsersActions.changePassword(password))
          }
        />
      </div>
    );
  };

  if (
    (!rolesDropdownLoading && rolesDropdownFailed) ||
    (!getUserLoading && getUserFailed)
  ) {
    return (
      <div
        style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}
      >
        <i className="flaticon-warning"></i>
        <p>
          Something went wrong. Please check your internet connection and try
          again.
        </p>
      </div>
    );
  } else {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={userData}
          validationSchema={UserEditSchema}
          handleChange={(e) => handleFormChange(e)}
          onSubmit={() => {
            saveUser();
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                {formLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div style={{zIndex: 1}} className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                <div className="card form-group">
                  <div className=" bg-primary card-title py-2 px-3"
                  >
                    <label className=" text-light mb-0 font-weight-bold">User information</label>
                    </div>
                    <div className="col-12">
                    <div className="form-group row">
                    {/* Name */}
                    <div className="col-lg-4">
                      <label>Name</label>
                      <Field
                        value={userData.name}
                        name="name"
                        component={Input}
                        placeholder="Name"
                        onChange={(e) => handleFormChange(e)}
                        disabled={formLoading}
                      />
                    </div>
                    {/* Login */}
                    <div className="col-lg-4">
                      <label>Email</label>
                      <Field
                        value={userData.emailId}
                        // onChange={(e) => handleFormChange(e)}
                        type="email"
                        name="email"
                        component={Input}
                        placeholder="Email"
                        disabled={true}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Phone</label>
                      <Field
                        value={userData.mobile}
                        onChange={(e) => handleFormChange(e)}
                        type="phone"
                        name="mobile"
                        component={Input}
                        placeholder="Phone"
                        disabled={formLoading}
                      />
                    </div>
                    
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4">
                        <label>Select Group</label>
                        <MultiSelect
                          disableSearch={true}
                          disabled={formLoading}
                          options={groupsListMulti}
                          value={selectedGroups}
                          onChange={setSelectedGroups}
                          overrideStrings={{
                            selectSomeItems: "Select...",
                            allItemsAreSelected: "All groups are selected.",
                            selectAll: "Select All",
                            search: "Search",
                            clearSearch: "Clear Search",
                          }}
                      />
                      </div>
                  </div>
                  </div>
                  </div>
                  <div className="card form-group">
                  <div className=" bg-primary card-title py-2 px-3"
                  >
                    <label className=" text-light mb-0 font-weight-bold">Subscription information</label>
                    </div>
                    <div className="col-12">
                  <div className="form-group row">
                  <div className="col-lg-4">
                      <label>Subscription</label>
                      <Field
                        value={userData.subscription}
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        name="subscription"
                        component={Input}
                        placeholder="Subscription"
                        disabled={true}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Next Payment</label>
                      <Field
                        value={
                          userData.nextSubscriptionDate
                            ? userData.nextSubscriptionDate.substring(0, 10)
                            : "NA"
                        }
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        name="nextSubscriptionDate"
                        component={Input}
                        placeholder="Next payment"
                        disabled={true}
                        customFeedbackLabel={false}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Sent Count</label>
                      <Field
                        value={userData.sentCount}
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        name="sentCount"
                        component={Input}
                        placeholder="Sent Count"
                        disabled={true}
                      />
                    </div>
                    </div>
                    <div className="form-group row">
                  <div className="col-lg-4">
                      <label>Payment Status</label>
                      <Field
                        value={userData.paymentFail == true ? "Failed": "Active"}
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        name="paymentFail"
                        component={Input}
                        placeholder="Payment failed status"
                        disabled={true}
                      />
                    </div>
                    <div className="col-lg-4" style={{display: userData.paymentFail == true ? "":"none"}}>
                      <label>Last Grace Period Date</label>
                      <Field
                        value={userData.userRestrictionDate ? userData.userRestrictionDate.substring(0, 10):""}
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        name="userRestrictionDate"
                        component={Input}
                        placeholder="Last grace period date"
                        disabled={true}
                      />
                    </div>
                  </div>
                  </div>
                  </div>
                  
                  <div className="card form-group">
                  <div className=" bg-primary card-title py-2 px-3"
                  >
                    <label className=" text-light mb-0 font-weight-bold">Monthly Limit</label>
                    </div>
                    <div className="col-12">
                  <div className="form-group row">
                  
                    <div className="col-lg-4">
                      <label>Text</label>
                      <Field
                        value={
                          userData.textLimit ? userData.textLimit:0
                        }
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Text"
                        disabled={true}
                        customFeedbackLabel={false}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Email</label>
                      <Field
                        value={
                          userData.emailLimit ? userData.emailLimit:0
                        }
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Email"
                        disabled={true}
                        customFeedbackLabel={false}
                      />
                    </div>
                    </div>
                    </div>
                    </div>

                    <div className="card form-group">
                  <div className=" bg-primary card-title py-2 px-3"
                  >
                    <label className=" text-light mb-0 font-weight-bold h5">Plingo Information</label>
                  </div>
                  <div className="col-12">
                  <div className="card form-group">
                  <div className=" bg-primary card-title py-2 px-3"
                  >
                    <label className=" text-light mb-0 font-weight-bold">Current Month</label>
                    </div>
                    <div className="col-12">
                  <div className="form-group row">
                  
                    <div className="col-lg-4">
                      <label>Text:</label>
                      <Field
                        value={
                          userData.sentCountTextCurrentMonth ? userData.sentCountTextCurrentMonth:0
                        }
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Text"
                        disabled={true}
                        customFeedbackLabel={false}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Email</label>
                      <Field
                        value={userData.sentCountEmailCurrentMonth? userData.sentCountEmailCurrentMonth:0}
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Email"
                        disabled={true}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Views</label>
                      <Field
                        value={userData.views? userData.views:0}
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Views"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                  
                    <div className="col-lg-4">
                      <label>Ad impression (Digilant)</label>
                      <Field
                        value={
                          userData.adImpCm ? userData.adImpCm:0
                        }
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Ad impression"
                        disabled={true}
                        customFeedbackLabel={false}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Open</label>
                      <Field
                        value={userData.openCm? userData.openCm:0}
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Open"
                        disabled={true}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Click</label>
                      <Field
                        value={userData.btnClkCm? userData.btnClkCm:0}
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Click"
                        disabled={true}
                      />
                    </div>
                  </div>
                  </div>
                  </div>
                  <div className="card form-group">
                  <div className=" bg-primary card-title py-2 px-3"
                  >
                    <label className=" text-light mb-0 font-weight-bold">Total</label>
                    </div>
                    <div className="col-12">
                  <div className="form-group row">
                  
                    <div className="col-lg-4">
                      <label>Text</label>
                      <Field
                        value={
                          userData.sentCountText?userData.sentCountText:0
                        }
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Text"
                        disabled={true}
                        customFeedbackLabel={false}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Email</label>
                      <Field
                        value={userData.sentCountEmail?userData.sentCountEmail:0}
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Email"
                        disabled={true}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Views</label>
                      <Field
                        value={userData.viewsTotal? userData.viewsTotal:0}
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Views"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                  
                    <div className="col-lg-4">
                      <label>Ad impression (Digilant)</label>
                      <Field
                        value={
                          userData.adImpTotal ? userData.adImpTotal:0
                        }
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Ad impression"
                        disabled={true}
                        customFeedbackLabel={false}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Open</label>
                      <Field
                        value={userData.openTotal? userData.openTotal:0}
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Open"
                        disabled={true}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Click</label>
                      <Field
                        value={userData.btnClkTotal? userData.btnClkTotal:0}
                        // onChange={(e) => handleFormChange(e)}
                        type="text"
                        component={Input}
                        placeholder="Click"
                        disabled={true}
                      />
                    </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>


                    
                  
                  <div className="card form-group">
                  <div className=" bg-primary card-title py-2 px-3"
                  >
                    <label className=" text-light mb-0 font-weight-bold h5">Account Actions</label>
                  </div>
                  <div className="col-12">
                  <div className="card form-group">
                  <div className=" bg-primary card-title py-2 px-3"
                  >
                    <label className=" text-light mb-0 font-weight-bold">Suspend Account</label>
                    </div>
                    <div className="col-12">
                  <div className="form-group row">
                  <div className="col-lg-4">
                      <label>Suspend Customer</label>
                      <Checkbox
                        onChange={(e) => handleFormChange(e)}
                        name="isSuspended"
                        isSelected={userData.isSuspended}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Suspention Reason</label>
                      <Field
                        value={userData.suspensionOrReleaseComment}
                        onChange={(e) => handleFormChange(e)}
                        type="text"
                        name="suspensionOrReleaseComment"
                        component={TextArea}
                        placeholder="Reason to suspend"
                      />
                    </div>
                  </div>
                  </div>
                  </div>
                  <div className="card form-group">
                  <div className=" bg-primary card-title py-2 px-3"
                  >
                    <label className=" text-light mb-0 font-weight-bold">Plingo Limit Extension</label>
                    </div>
                    <div className="col-12">
                  <div className="form-group row">
                  {(userData.subscription !== 'Special' && !isSpecialPlan)&&<div className="col-lg-4">
                    <label>Recurring</label>
                      <Checkbox
                        onChange={(e) => handleFormChange(e)}
                        name="isExtensionLimitPermanent"
                        isSelected={userData.isExtensionLimitPermanent}
                        disabled={enableExtention}
                      />
                    </div>}
                  <div className="col-lg-4">
                    <label>Text Limit</label>
                      <Field
                        type="number"
                        value={userData.textLimit}
                        name="textLimit"
                        component={Input}
                        placeholder="Name"
                        onChange={(e) => handleFormChange(e)}
                        disabled={enableExtention}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label>Email Limit</label>
                      <Field
                        type="number"
                        value={userData.emailLimit}
                        name="emailLimit"
                        component={Input}
                        placeholder="Name"
                        onChange={(e) => handleFormChange(e)}
                        disabled={enableExtention}
                      />
                    </div>
                  </div>
                  </div>
                  </div>
                  <div className="card form-group">
                  <div className=" bg-primary card-title py-2 px-3"
                  >
                    <label className=" text-light mb-0 font-weight-bold">Change Plan</label>
                    </div>
                    <div className="col-12">
                  <div className="form-group row">
                  <div className="col-lg-4">
                    <label>Current Plan:</label>
                      <Field
                        value={userData.subscription}
                        type="text"
                        name="subscription"
                        component={Input}
                        placeholder="Subscription"
                        disabled={true}
                      />
                    </div>
                    {userData.subscription !== 'Special' &&<div className="col-lg-4">
                      <label>Change Plan</label>
                      <Select
                        name="subscriptionSelected"
                        onChange={(e) => handleFormChange(e)}
                        disabled={formLoading}
                        value={userData.subscriptionSelected}
                      >
                        { userData.subscriptions && userData.subscriptions.map((subscription) => 
                          <option key={subscription._id} value={subscription._id}>{subscription.subscriptionTitle}</option>
                        )}
                      </Select>
                        <p style={{color: 'red',display: (userData.isPlanDowngrade || userData.engToEcom ? "block" : "none")}}>{msgForUpgradeDowngrade}</p>
                    </div>}
                    <div className="col-lg-4">
                      <label style={{display: dropDownMonYearDisable ? "none":"block"}}>Subscribe For</label>
                      <Select
                        style={{display: dropDownMonYearDisable ? "none":"block"}}
                        name="subscribeFor"
                        onChange={(e) => handleFormChange(e)}
                        disabled={dropDownMonYearDisable}
                        value={userData.subscribeFor}
                      >
                        <option key="Monthly" value="Monthly">Monthly</option>
                        <option key="Yearly" value="Yearly">Yearly</option>
                      </Select>

                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4" style={{display: dateAmountRow ? "none":"block"}}>
                     <label>Date</label>
                      <DatePicker className="form-control" selected={userData.dateForAmount} onChange={(e) => handleFormChange(e,true)} name="dateForAmount"/>
                    </div>
                    <div className="col-lg-4" style={{display: dateAmountRow ? "none":"block"}}>
                      <label>Amount</label>
                      <Field
                        value={userData.amountDeduct}
                        onChange={(e) => handleFormChange(e)}
                        type="number"
                        name="amountDeduct"
                        component={Input}
                        placeholder="Amount"
                      />
                    </div>
                  </div>
                  </div>
                  </div>
                  <div className=" bg-primary card-title py-2 px-3">
                    <label className=" text-light mb-0 font-weight-bold">Twilio Notification Service</label>
                    </div>
                    <div className="col-12">
                  <div className="form-group row">
                  <div className="col-lg-8">
                      <label>Notification Service</label>
                      <Select
                        name="twilioNotifyLongSidSelected"
                        onChange={(e) => handleFormChange(e)}
                        disabled={formLoading}
                        value={userData?.twilioNotifyLongSidSelected || userData?.twilioNotifyLongSid}
                      >
                       <option key="" value="" ></option>
                        {twilioServicesList && twilioServicesList.map((services) => 
                          <option key={services.value} value={services.value} selected={userData.twilioNotifyLongSid === services.value ? true : false}>{services.displayValue}</option>
                        )}
                      </Select>
                    </div>
                    </div>
                    </div>
                  </div>
                  </div>
                  
                  
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={!formLoading ? onHide : undefined}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="button"
                  onClick={!formLoading ? onHide : undefined}
                  onClick={() => !formLoading && handleSubmit()}
                  className="btn btn-primary btn-elevate"
                  disabled={formLoading}
                >
                  Submit
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    );
  }
}
