import React from "react";
import { Modal } from "react-bootstrap";

import GroupDialogHeader from './GroupDialogHeader';
import { GroupForm } from "./GroupForm";

export default function GroupDialog({id, onHide, show, edit, saveUser}) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <GroupDialogHeader groupEdit={edit} id={id} />
      <GroupForm
        groupID={id}
        actionsLoading={false}
        onHide={onHide}
        edit={edit}
      />
    </Modal>
  );
}