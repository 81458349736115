import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as manageUsers from "../app/modules/UserSettings/redux/manageUsersRedux";
import * as manageDashboard from "../app/modules/Dashboard/redux/manageDashboardRedux";
import * as general from "./generalReducer";

const appReducer = combineReducers({
  general: general.reducer,
  auth: auth.reducer,
  manageUsers: manageUsers.reducer,
  manageDashboard: manageDashboard.reducer
});

export const rootReducer = (state, action) => {
  if (action.type === auth.actionTypes.setShouldLogout) {
    let { flag } = action.payload;
    if (flag === true) {
      state = undefined;
    }
  }

  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([
    auth.saga(),
    manageUsers.saga(),
    manageDashboard.saga(),
  ]);
}
