import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import Popover from "@material-ui/core/Popover";
import SVG from "react-inlinesvg";
import { Field } from "formik";
import { Input } from "../../../../_metronic/_partials/controls";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table from "../../../components/Table";
import ActionsColumnFormatter from "../components/ActionsColumnFormatter";
import UserDialog from "../components/UserDialog";
import { actions as manageUsersActions } from "../redux/manageUsersRedux";

export default function RevenuePayment() {
  const dispatch = useDispatch();
  const {
    listLoading,
    paymentsList,
    totalPaymentsRecords,
    currentPagePayment,
    maxPaymentsPerPage,
    paymentsSortField,
    paymentsSortOrder,
    // updateCustomerDetailsSuccess
  } = useSelector((state) => state.manageUsers);
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(true);
  const [dialogID, setDialogID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cookies] = useCookies(["accessToken"]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(
      manageUsersActions.getPaymentsList(
        paymentsSortField,
        paymentsSortOrder,
        0,
        maxPaymentsPerPage,
        cookies.accessToken,
        search
      )
    );
  }, []);

  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalPaymentsRecords ? totalPaymentsRecords : 0,
    sizePerPage: maxPaymentsPerPage,
    page: currentPagePayment + 1,
  };

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      dispatch(
        manageUsersActions.changeCurrentPagePayment(
          page - 1,
          maxPaymentsPerPage,
          cookies.accessToken,
          search
        )
      );
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (sortField !== paymentsSortField || sortOrder !== paymentsSortOrder) {
        dispatch(
          manageUsersActions.changeSortPayment(
            sortField,
            sortOrder,
            cookies.accessToken,
            search,
          )
        );
      }
    }
  };

  const applyFilter = () => {
    // dispatch(manageUsersActions.setFilter(true));
    // dispatch(manageUsersActions.setcurrentPagePayment(0));
    // dispatch(
    //   manageUsersActions.getUsersList(
    //     paymentsSortField,
    //     paymentsSortOrder,
    //     0,
    //     maxPaymentsPerPage,
    //     cookies.accessToken
    //   )
    // );
  };

  const onHideUserDialog = () => {
    setShowModal(false);
    setDialogID(null);
    dispatch(manageUsersActions.closeUserDialog());
  };

  const openEditUserDialog = (edit, id) => {
    setEdit(edit);
    setDialogID(id);
    setShowModal(true);
  };

  const searchCustomer = (search) => {
    dispatch(
      manageUsersActions.getPaymentsList(
        paymentsSortField,
        paymentsSortOrder,
        0,
        maxPaymentsPerPage,
        cookies.accessToken,
        search
      )
    );
  }

  const handleFormChange = (e) => {
    setSearch(e.target.value);
  }
  
  let celli = currentPagePayment + 1 > 1 ? currentPagePayment * maxPaymentsPerPage + 1 : 1;
  const tableColumns = [
    {
      dataField: "_id",
      text: "ID",
      formatter: function(cell, row) {
        return row.indexNo;
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sortCaret: sortCaret,
      headerSortingClasses,
      sort: true,
    },
    {
      dataField: "emailId",
      text: "Email",
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "createdAt",
      text: "Payment Date",
      sortCaret: sortCaret,
      headerSortingClasses,
      sort: true,
      formatter: function(cell, row) {
        return <p>{cell.substring(0, 10)}</p>;
      },
    },
    {
      dataField: "amount",
      text: "Amount",
    },
    {
      dataField: "subscriptionTitle",
      text: "Purpose",
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Card>
      <CardHeader title="Payments">
        <CardHeaderToolbar>
          <div style={{ marginRight: "10px" }}>
            {/* <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <span className="svg-icon svg-icon-sm svg-icon-gray">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")} />
              </span>
              Filter
            </button> */}
            {/* <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <UsersFilterForm
                applyFilter={() => applyFilter()}
                onHide={() => setAnchorEl(null)}
              />
            </Popover> */}
          </div>
          <div className="row search-row">
            <div className="col-md-8">
              <input
                className="form-control"
                type="text"
                name="search"
                placeholder="search"
                value={search}
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <button
              type="button"
              className="btn btn-black-primary"
              onClick={() => searchCustomer(search)}
            >
              Search
            </button>
          </div>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Table
          keyField="_id"
          listLoading={listLoading}
          entities={paymentsList ? paymentsList : []}
          columns={tableColumns}
          paginationOptions={paginationOptions}
          totalCount={totalPaymentsRecords}
          handleTableChange={(type, newState) =>
            handleTableChange(type, newState)
          }
          sortState={{ dataField: paymentsSortField, order: paymentsSortOrder }}
        />
      </CardBody>
      <UserDialog
        id={dialogID}
        show={showModal}
        edit={edit}
        onHide={() => onHideUserDialog()}
      />
    </Card>
  );
}
