import { put, takeLatest, call, all, select } from "redux-saga/effects";
import queryString from "query-string";
import ShortUniqueId from "short-unique-id";

import { getApiToken, postApiToken, putApiToken } from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
import { actions as authActions } from "../../Auth/_redux/authRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";

const uid = new ShortUniqueId();
export const actionTypes = {
  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  GET_DASHBOARD_DATA_LOADING: "GET_DASHBOARD_DATA_LOADING",
  CHANGE_DASHBOARD_DATA: "CHANGE_DASHBOARD_DATA",
};

const initialUsersState = {
  dashboardData: {
    totalSentCountText:"",
    totalSentCountEmail:"",
    totalViews:"",
    topPlingos:"",
    topPlingoViews:"",
    topCustomers:"",
  },
  getDashboardDataLoading: false,
};

export const reducer = (state = initialUsersState, action) => {
  switch (action.type) {
    
    case actionTypes.GET_DASHBOARD_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        getDashboardDataLoading: flag,
      };
    }

    case actionTypes.CHANGE_DASHBOARD_DATA: {
      const { dashboardData } = action.payload;
      
      return {
        ...state,
        dashboardData: {
            totalSentCountText:dashboardData.totalSentCountText,
            totalSentCountEmail:dashboardData.totalSentCountEmail,
            totalViews:dashboardData.totalViews,
            topPlingos:dashboardData.topPlingos,
            topCustomers:dashboardData.topCustomers,
            topPlingoViews: dashboardData.topPlingoViews,
        }
      };
    }
    
    default:
      return state;
  }
};

export const actions = {
  changeDashboardData: (dashboardData) => ({
    type: actionTypes.CHANGE_DASHBOARD_DATA,
    payload: { dashboardData },
  }),
  getDashboardData: (accessToken,month,year) => ({
    type: actionTypes.GET_DASHBOARD_DATA,
    payload: { accessToken,month,year },
  }),
  getDashboardDataLoading: (flag) => ({
    type: actionTypes.GET_DASHBOARD_DATA_LOADING,
    payload: { flag },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.GET_DASHBOARD_DATA, getDashboardDataSaga);
}

function* getDashboardDataSaga(action) {
  let { accessToken,month,year } = action.payload;
  yield all([
    put(actions.getDashboardDataLoading(true)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("GET_DASHBOARD_DATA")}`,
      queryString.stringify({
        month: month,
        year: year,
      }),
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.changeDashboardData(responseData.data)),
          put(actions.getDashboardDataLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.getDashboardDataLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.getDashboardDataLoading(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Get Dashboard Data",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.getDashboardDataLoading(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Get Dashboard Data",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.getDashboardDataLoading(false)),
    ]);
    yield put(
      generalActions.addToast(
        "Get Dashboard Data",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}




