import config from "./config";

const url = {
  LOGIN_URL: "api/v1/auth/loginAdmin",
  GET_TOKEN_USER_URL: "api/admin/auth/user",
  USER_ADMIN_URL: "api/admin/adminuser",
  ROLES_DROPDOWN_URL: "api/admin/roles/dropdown",
  ROLE_LIST_URL: "api/admin/roles",
  CREATE_ROLE_URL: "api/admin/roles",
  SHOW_ROLE_URL: "api/admin/roles",
  UPDATE_ROLE_URL: "api/admin/roles",
  PERMISSION_LIST_URL: "api/admin/permissions",
  GET_CUSTOMERS: "api/v1/customer/getCustomers",
  GET_CUSTOMER: "api/v1/customer/getCustomer",
  UPDATE_CUSTOMER_FROM_ADMIN: "api/v1/customer/updateCustomerFromAdmin",
  GET_DASHBOARD_DATA: "api/v1/customer/getDashboardData",
  GET_PAYMENTS: "api/v1/customer/getPayments",
  ADD_UPDATE_GROUP: "api/v1/adminUserGroup/addUpdateGroup",
  GET_GROUP_LIST: "api/v1/adminUserGroup/getGroupList",
  GET_GROUP: "api/v1/adminUserGroup/getGroup",
  DELETE_USER: "api/v1/customer/deleteAccountFromAdmin",
};

const apiUrl = (name) => {
  return `${config.API_ENDPOINT}${url[name]}`;
};

export default apiUrl;
