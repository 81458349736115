import React, { useEffect, useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import ManageUsers from "./pages/ManageUsers";
import ManageGroup from "./pages/ManageGroup";

function UserSettingsPage() {
 
  return (
    <div>
      <Switch>
        <Redirect
          exact={true}
          from="/user-settings"
          to="/user-settings/manage-users"
        />
        <ContentRoute
          path="/user-settings/manage-users"
          component={ManageUsers}
        />
         <ContentRoute
          path="/user-settings/manage-group"
          component={ManageGroup}
        />
      </Switch>
    </div>
  );
}

export default UserSettingsPage;
