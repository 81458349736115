import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditUserDialog, deleteUser }
) {
  return (
    <>
      <a
        title="View User"
        className="btn btn-icon btn-light btn-hover-primary btn-sm"
        onClick={() => openEditUserDialog(row._id)}
      >
        <span
          title="View user"
          className="svg-icon svg-icon-md svg-icon-primary"
        >
          <SVG
            title="View user"
            src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")}
          />
        </span>
      </a>
      <a
        title="Delete User"
        className="btn btn-icon btn-light btn-hover-danger btn-sm mx-4"
        onClick={() => deleteUser(row._id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
    </>
  );
}
