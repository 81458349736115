import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import Popover from "@material-ui/core/Popover";
import SVG from "react-inlinesvg";
import { Field } from "formik";
import { Input,Select } from "../../../../_metronic/_partials/controls";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table from "../../../components/Table";
import ActionsColumnFormatter from "../components/ActionsColumnFormatter";
import UserDialog from "../components/UserDialog";
import { actions as manageUsersActions } from "../redux/manageUsersRedux";

export default function ManageUsers() {
  const dispatch = useDispatch();
  const {
    listLoading,
    usersList,
    totalUsersRecords,
    currentPage,
    maxUsersPerPage,
    usersSortField,
    usersSortOrder,
    groupsSortField,
    groupsSortOrder,
    maxGroupsPerPage,
    groupsListMulti,
    // updateCustomerDetailsSuccess
  } = useSelector((state) => state.manageUsers);
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(true);
  const [dialogID, setDialogID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cookies] = useCookies(["accessToken"]);
  const [search, setSearch] = useState("");
  const [selectGroup, setSelectGroup] = useState("");

  useEffect(() => {
    dispatch(
      manageUsersActions.getUsersList(
        usersSortField,
        usersSortOrder,
        0,
        maxUsersPerPage,
        cookies.accessToken,
        search
      )
    );
    dispatch(
      manageUsersActions.getGroupsList(
        groupsSortField,
        groupsSortOrder,
        0,
        maxGroupsPerPage,
        cookies.accessToken,
        true,
      )
    );
  }, []);
  
  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalUsersRecords ? totalUsersRecords : 0,
    sizePerPage: maxUsersPerPage,
    page: currentPage + 1,
  };

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      dispatch(
        manageUsersActions.changeCurrentPage(
          page - 1,
          maxUsersPerPage,
          cookies.accessToken,
          search,
          selectGroup,
        )
      );
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (sortField !== usersSortField || sortOrder !== usersSortOrder) {
        dispatch(
          manageUsersActions.changeSort(
            sortField,
            sortOrder,
            cookies.accessToken,
            search,
            selectGroup,
          )
        );
      }
    }
  };

  const applyFilter = () => {
    // dispatch(manageUsersActions.setFilter(true));
    // dispatch(manageUsersActions.setCurrentPage(0));
    // dispatch(
    //   manageUsersActions.getUsersList(
    //     usersSortField,
    //     usersSortOrder,
    //     0,
    //     maxUsersPerPage,
    //     cookies.accessToken
    //   )
    // );
  };

  const onHideUserDialog = () => {
    setShowModal(false);
    setDialogID(null);
    dispatch(manageUsersActions.closeUserDialog());
  };

  const openEditUserDialog = (edit, id) => {
    setEdit(edit);
    setDialogID(id);
    setShowModal(true);
  };

  const deleteUser = (id) => {
    if (window.confirm("This account will be permanently deleted. Once you delete this account, there is no going back. Please be certain.")) {
      dispatch(
        manageUsersActions.deleteUser(
          id,
          cookies.accessToken,
        )
      );
    }
  };

  const searchCustomer = (search) => {
    dispatch(
      manageUsersActions.getUsersList(
        usersSortField,
        usersSortOrder,
        0,
        maxUsersPerPage,
        cookies.accessToken,
        search,
        selectGroup,
      )
    );
  }

  const handleFormChange = (e) => {
    setSearch(e.target.value);
  }

  const handleGroupChange = (e) => {
    setSelectGroup(e.target.value);
  }
  
  let celli = currentPage + 1 > 1 ? currentPage * maxUsersPerPage + 1 : 1;
  const tableColumns = [
    {
      dataField: "_id",
      text: "ID",
      formatter: function(cell, row) {
        return row.indexNo;
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "emailId",
      text: "Email",
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "mobile",
      text: "Phone",
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "subscriptionTitle",
      text: "Subscription",
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sortCaret: sortCaret,
      headerSortingClasses,
      sort: true,
      formatter: function(cell, row) {
        return cell.substring(0, 10);
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditUserDialog: (_id) => openEditUserDialog(true, _id),
        deleteUser: (id) => deleteUser(id),
      },
    },
  ];

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Card>
      <CardHeader title="Users list">
        <CardHeaderToolbar>
          <div style={{ marginRight: "10px" }}>
            {/* <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <span className="svg-icon svg-icon-sm svg-icon-gray">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")} />
              </span>
              Filter
            </button> */}
            {/* <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <UsersFilterForm
                applyFilter={() => applyFilter()}
                onHide={() => setAnchorEl(null)}
              />
            </Popover> */}
          </div>
          <div className="row search-row" style={{marginRight: "1rem"}}>
            <select onChange={handleGroupChange} className="form-control" name="country" value={selectGroup}>
              <option value="">Select Group</option>
              {groupsListMulti.map((e, key) => {
                  return <option key={key} value={e.value}>{e.label}</option>;
              })}
            </select>
          </div>
          <div className="row search-row">
            <div className="col-md-8">
              <input
                className="form-control"
                type="text"
                name="search"
                placeholder="Search with name, email"
                value={search}
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <button
              type="button"
              className="btn btn-black-primary"
              onClick={() => searchCustomer(search)}
            >
              Search
            </button>
          </div>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Table
          keyField="_id"
          listLoading={listLoading}
          entities={usersList ? usersList : []}
          columns={tableColumns}
          paginationOptions={paginationOptions}
          totalCount={totalUsersRecords}
          handleTableChange={(type, newState) =>
            handleTableChange(type, newState)
          }
          sortState={{ dataField: usersSortField, order: usersSortOrder }}
        />
      </CardBody>
      <UserDialog
        id={dialogID}
        show={showModal}
        edit={edit}
        onHide={() => onHideUserDialog()}
      />
    </Card>
  );
}
