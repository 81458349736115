import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { useCookies } from "react-cookie";
import queryString from "query-string";
import * as auth from "../_redux/authRedux";
// import ReCAPTCHA from "react-google-recaptcha";
import { FormAlert } from "../../../../_metronic/_partials/controls";
import { postApi } from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
// import config from "../../../../configs/config";
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};
function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["accessToken"]);
  // const [checkCaptchaCheckbox, setCaptchaCheckbox] = useState(false);
  // const [captchaFlag, setCaptchaFlag] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [loginError, setLoginError] = useState("Something went wrong");
  const history = useHistory();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      // .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });
  // function onChange(value) {
  //   if (value) {
  //     setCaptchaFlag(true);
  //     setCaptchaCheckbox(false);
  //   }
  // }
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const handleLoginError = (response) => {
    response.json().then((errorData) => {
      if (errorData && errorData.error && !errorData.message) {
        setShowLoginError(true);
        setLoginError(errorData.error);
      } else if (errorData && errorData.message) {
        setShowLoginError(true);
        setLoginError(errorData.message);
      } else {
        setShowLoginError(true);
        setLoginError("Something went wrong!");
      }
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // if (captchaFlag) {
        setShowLoginError(false);
        enableLoading();
        setTimeout(() => {
          const body = {
            email: values.email,
            password: values.password,
          };
          const stringifiedBody = queryString.stringify(body);
          postApi(apiUrl("LOGIN_URL"), stringifiedBody)
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else if (response.status === 422) {
                disableLoading();
                setSubmitting(false);
                handleLoginError(response);
              } else {
                disableLoading();
                setSubmitting(false);
                setShowLoginError(true);
                setLoginError("Email/Username or Password is incorrect");
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.NORESPONSE",
                  })
                );
              }
            })
            .then((responseData) => {
              if(responseData.statusCode == 200) {
                disableLoading();
                if (responseData && responseData.data) {
                  let seconds = Math.round(new Date() / 1000);
                  seconds = seconds + responseData.data.expire_in;
                  let tokenExpiryDate = new Date(seconds * 1000);
                  setCookie("accessToken", responseData.data.accessToken, {
                    path: "/",
                    expires: tokenExpiryDate,
                  });
                  props.login(responseData.data.user);
                }
              } else {
                disableLoading();
                setSubmitting(false);
                setShowLoginError(true);
                setLoginError("Email/Username or Password is incorrect");
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.NORESPONSE",
                  })
                );
              }
            })
            .catch((error) => {
              console.log("error: ", error);
              disableLoading();
              setSubmitting(false);
              setShowLoginError(true);
              setLoginError("Something went wrong!");
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.NORESPONSE",
                })
              );
            });
        }, 1000);
      // } else {
      //   // setCaptchaCheckbox(true);
      //   disableLoading();
      //   setSubmitting(false);
      //   setStatus(
      //     intl.formatMessage({
      //       id: "AUTH.VALIDATION.INVALID_LOGIN",
      //     })
      //   );
      // }
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-9">
        <h3>
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-center username-password">
          Enter your username and password
        </p>
        <FormAlert isEnabled={showLoginError} message={loginError} />
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email/Username"
            type="text"
            className={`form-control form-control-solid h-auto py-6 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-6 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* <div className="form-group fv-plugins-icon-container recaptha-row">
          <ReCAPTCHA
            sitekey={config.RECAPTCHA_SITE_KEY}
            onChange={onChange}
          />
          {checkCaptchaCheckbox ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">Please Check Captcha</div>
            </div>
          ) : null}
        </div> */}
        <div className="d-flex flex-wrap flex-column justify-content-between sign-in-row">
        <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-black-primary font-weight-bold px-9 py-5`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          {/* <Link
            to="/auth/forgot-password"
            className="block text-hover-primary my-3 mr-2 text-center"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link> */}
          
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
