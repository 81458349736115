import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import { withCookies } from "react-cookie";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";

class Logout extends Component {
  componentDidMount() {
    // this.props.logout();
    console.log('logout mounted')
  }

  render() {
    return <Redirect to="/auth/login" />;
  }
}

export default withCookies(Logout);
